import { connect } from "react-redux";
import MiniDrawer from "./components/AppDrawer";
import { switchListTab, switchTab, switchContractTab,switchContractCostTab } from "./actioncreators";
import ListComponents from "./components/ListComponents";
import ContractComponent from "./components/ContractComponent";
import NewPlod from "./components/NewPlod";
import SignOffComponent from "./components/SignOffComponent";
import RigComponent from "./components/RigComponent";
import ClientComponents from "./components/ClientComponents";
import BitComponent from "./components/BitComponent";
import TempDrawer from "./components/TempDrawer";

export const Drawer = connect(
  (state) => ({
    ...state.ui,
  }),
  (dispatch) => ({
    switchTab(index) {
      dispatch(switchTab(index));
    },
  })
)(TempDrawer);

export const Clients = connect(
  (state) => ({
    ...state.ui.clients,
  }),
  (dispatch) => ({})
)(ClientComponents);

export const Lists = connect(
  (state) => ({
    ...state.ui.lists,
  }),
  (dispatch) => ({
    switchTab(index) {
      dispatch(switchListTab(index));
    },
  })
)(ListComponents);

export const Rigs = connect(
  (state) => ({
    ...state.ui.contracts,
    tasks: state.tasks,
    rigs: state.rigs,
    consumables: state.consumables,
  }),
  (dispatch) => ({
    switchTab(index) {
      dispatch(switchContractTab(index));
    },
  })
)(RigComponent);

export const Contracts = connect(
  (state) => ({
    ...state.ui.contracts,
    tasks: state.tasks,
    // tasks: [
    //   { ID: 1, TaskName: "Task1" },
    //   { ID: 2, TaskName: "Task2" },
    // ],
    rigs: state.rigs,
    consumables: state.consumables,
  }),
  (dispatch) => ({
    switchTab(index) {
      dispatch(switchContractTab(index));
    },
  })
)(ContractComponent);


// contract_cost
export const Contract_cost = connect(
  (state) => ({
    ...state.ui.contract_cost,
    // tasks: state.tasks,
    // tasks: [
    //   { ID: 1, TaskName: "Task1" },
    //   { ID: 2, TaskName: "Task2" },
    // ],
    rigs: state.rigs,
    consumables: state.consumables,
  }),
  (dispatch) => ({
    switchTab(index) {
      dispatch(switchContractTab(index));
    },
  })
)(ContractComponent);

export const Bits = connect(
  (state) => ({
    ...state.ui.bits,
    bitTypes: state.bitTypes,
    bitSizes: state.bitSizes,
  }),
  (dispatch) => ({})
)(BitComponent);

export const Plod = connect(
  (state) => ({
    ...state.ui.newPlod,
    tasks: state.tasks,
    rigs: state.rigs,
    consumables: state.consumables,
  }),
  (dispatch) => ({})
)(NewPlod);

export const SignOff = connect(
  (state) => ({}),
  (dispatch) => ({})
)(SignOffComponent);
