import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import EplodEntityTable from "./ListsComponents/EplodEntityTable";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import globals from "../globals";
import "date-fns";
import { FormControl } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { OutlinedInput } from "@material-ui/core";

const styles = (theme) => ({
  rows: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffffff",
  },
});

class NewPlod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      miningCompanies: [],
      contracts: [],
      selectedMiningCompany: null,
      selectedContract: null,
      selectedRig: null,
      date: new Date(),
      shiftId: null,
      showBackdrop: false,
      dayNight: "Day",
      // site: { ID: 0, SiteName: "Site" },
      // site: { value: "0", label: "Site" },
      site: "Site",
      rig: "Rig",
      rigID: 0,
      siteID: 0,
      rigs: [],
      consumables: [],
      tasks: [],
      sitesList: [],
      tasksList:[],
      consumablesList:[],
    };

  var url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/~GetAll/`;
    this.statusRef = React.createRef();
    fetch(url, { method: "post" })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (
          result.length != 0 &&
          result != null &&
          result != undefined &&
          result != null &&
          result != ""
        ) {
          this.setState({
            loading: false,
            miningCompanies: result,
            selectedMiningCompany: result[0].ID,
          });
          this.onChangeMiniCompany({ target: { value: result[0].ID } });
        }
      });
  }

  onChangeMiniCompany = (event) => {
    // console.log(event.target.value);
    this.setState({ selectedMiningCompany: event.target.value });
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${event.target.value}/EC_Contracts/~GetAll/`;
    fetch(url, { method: "post" })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.length !== 0) {
          this.setState({
            loading: false,
            contracts: result,
            selectedContract: result[0].ID,
          });
          this.contractSelectChange({ target: { value: result[0].ID } });
        }
      });
  };

  contractSelectChange = (event) => {
    // console.log(event.target.value);
    this.setState({ selectedContract: event.target.value });
    this.getContractSites(event.target.value)
    this.getRigsList(event.target.value);
    this.getTasks()
    this.getConsumables()

   
  };

  getRigsList(contractId){
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${contractId}/`;
    const body = `{
                    EC_ContractRigs() {  	      
                        ID
                        EA_Rig(){        
                      ID
                      RigName
                    }
                    }
                  }`;

    const urlRig = url + "EC_ContractRigs/~GraphQL";
    fetch(urlRig, { method: "post", body: body })
      .then(async (response) => {
        return await response.json();
      })
      .then((result) => {
        if (result.data) {
          const mappedResult = result.data?.EC_ContractRigs.map(
            (l) => l.EA_Rig[0]
          );

          // this.setState({ rigs: [{ ID: 0, RigName: "Rig" }] });
          if (mappedResult.length !== 0) {
            let a = [{ ID: 0, RigName: "Rig" }];
            this.setState({
              loading: false,
              rigs: [...a, ...mappedResult],
              selectedRig: a.RigName,
              // rigID: 0,
              rig: 0,
            });
          }
        }
      })
      .catch((err) => {
        console.log("EC_ContractRigs Catch Error :::::>>>>\n", err);
      });
  }

  getContractSites(contractId){
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${contractId}/`;

    fetch(url + "EC_ContractSite/~GetAll/", { method: "post" })
    .then(async (response) => {
      let jsonData = await response.json();

      if (Array.isArray(jsonData)) {
        const mappedResult = jsonData.map((l) => ({
          ID: l.ID,
          SiteName: l.SiteName,
        }));
        // const mappedResult = result.map((l) => l.SiteName);

        // console.log(mappedResult);
        if (mappedResult.length != 0) {
          let a = [{ ID: 0, SiteName: "Site" }];
          this.setState({
            loading: false,
            sitesList: [...a, ...mappedResult],
            site: 0 
          });
        }
      }
    })
    .catch((err) => {
      console.log("EC_ContractSite Catch Error :::::>>>>\n", err);
    });
  }
  
  getTasks(){
    var url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${this.state.selectedContract}/EC_ContractTasks/~GraphQL/`;
    fetch(url, {
      method: "post",
      body:`{
        EC_ContractTasks() {  	      
                EA_Task(){        
                        ID
                      TaskName
               }
         }
   }` 
    })


    .then(async (response) => {
      // debugger;
      let jsonData = await response.json();

      
      if (jsonData.data && Array.isArray(jsonData.data['EC_ContractTasks'])) {
        
        const mappedResult = jsonData.data['EC_ContractTasks'].map((l) => {
          if(['EA_Task']?.length){
            return {
              ID: l["EA_Task"][0].ID,
            TaskName: l["EA_Task"][0].TaskName,
            }
          }
        });
       
        if (mappedResult.length != 0) {
          let a = [{ ID: 0, TaskName: "Task" }];
          this.setState({
            loading: false,
            tasksList: [...a, ...mappedResult],
          });
          // this.setState({ site: a[0].SiteName });
          this.setState({ task: 0 });
        }
      }
    })
  }

  getConsumables(){
    var url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${this.state.selectedContract}/EC_ContractConsumables/~GraphQL/`;
    fetch(url, {
      method: "post",
      body: `{
        EC_ContractConsumables() {  	      
             EA_Consumable(){
             ID
             ConsumableName
            }      
          }
        }`
    })
    // .then(async (response) => {
    //   var jsonData = await response.json();
    //   console.log(jsonData)
    //   if (response.status == 200) 
    //   if(jsonData){
      
    //     this.setState({
    //       consumablesList: [{ ID: -1, ConsumableName: "- Select -" }, ...jsonData.data],
    //     });
    // }
    // });

    .then(async (response) => {
      let jsonData = await response.json();

      if (jsonData.data && Array.isArray(jsonData.data['EC_ContractConsumables'])) {
        const mappedResult = jsonData.data['EC_ContractConsumables'].map((I) => {
        
        if(["EA_Consumable"]?.length){
          return {
          ID :I["EA_Consumable"][0].ID,
          ConsumableName : I["EA_Consumable"][0].ConsumableName,
         }
        }
      });
        // const mappedResult = result.map((l) => l.SiteName);

        // console.log(mappedResult);
        if (mappedResult.length != 0) {
          let a = [{ ID: 0, ConsumableName: "Consumables" }];
          this.setState({
            loading: false,
            consumablesList: [...a, ...mappedResult],
          });
          // this.setState({ site: a[0].SiteName });
          this.setState({ consumables: 0 });
        }
      }
    })
  }

  rigSelectChange = (event) => {

    // console.log(event.target.value);
    this.setState({ selectedRig: event.target.value });
  
  };


  savePlod = () => {
    this.setState({ showBackdrop: true });
    
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${this.state.selectedContract}/SH_Sites/ID_${this.state.siteID}/SH_Shifts/~AddItem`;
  
    let body = JSON.stringify({
      DayNight: this.state.dayNight,
      Date: this.state.date,
      Status: this.statusRef.current.value,
      // initialRigID: this.state.selectedRig,
      EA_Rigs: [{ Link_Value: { ID: this.state.rigID } }],
      EA_Sites: [{ Link_Value: { ID: this.state.siteID } }],
    });
    
    fetch(url, {
      method: "post",
      body: body,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ shiftId: result.ID });
        this.setState({ showBackdrop: false });
      });
  };

  render() {
    const { classes } = this.props;
    const { site, siteID, sitesList, rig, rigID, rigs } = this.state;
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" className={classes.rows}>
          {/* <Link color="inherit" href="/" onClick={this.handleClick}>
            Material-UI
          </Link>
          <Link
            color="inherit"
            href="/getting-started/installation/"
            onClick={this.handleClick}
          >
            Core
          </Link> */}
          <Typography color="textPrimary" style={{fontWeight:"450"}}>Eplod III</Typography>
        </Breadcrumbs>

        <div style={{display:"inline-flex"}}>
        <Typography style={{fontSize:"18px"}}>Client</Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.selectedMiningCompany}
          onChange={this.onChangeMiniCompany}
        >
          {this.state.miningCompanies.map((m, i) => (
            <MenuItem key={i} value={m.ID}>
              {m.MiningCompanyName}
            </MenuItem>
          ))}
        </Select>
      </div>
      <br /><br />
        
      <div style={{display:"inline-flex"}}>
        <Typography style={{fontSize:"18px"}}>Contract</Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {this.state.selectedContract && (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select" 
            value={this.state.selectedContract}
            onChange={this.contractSelectChange}
          >
            {this.state.contracts.map((m, i) => (
              <MenuItem key={i} value={m.ID}>
                {m.ContractName}
              </MenuItem>
            ))}
          </Select>
        )}
        </div>
        <br />
        <br />

        <div style={{display:"inline-flex"}}>
      <Typography style={{fontSize:"18px"}}>Site</Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={site}
                  defaultValue=""
                  onChange={(event) => {
                    this.setState({
                      site: event.target.value,
                      siteID: event.target.value,
                    });
                  }}
                >
                  {sitesList.map((item) => (
                    <MenuItem key={item.ID} value={item.ID}>
                      {item.SiteName}
                    </MenuItem>
                  ))}
                </Select>
                </div>

        <Accordion defaultExpanded>
          {/* <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <div className={classes.column}>
              <Typography className={classes.heading}>
                Select Rig & Set Status
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}>
                Select Plod Date
              </Typography>
            </div>
          </AccordionSummary> */}
          <AccordionDetails className={classes.details}>
            <div className={classes.column}>
              {/* {this.state.selectedRig && (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.selectedRig}
                  onChange={this.rigSelectChange}
                >
                  {this.state.rigs.map((m, i) => (
                    <MenuItem key={i} value={m.ID}>
                      {m.RigName}
                    </MenuItem>
                  ))}
                </Select>
              )} */}
              {/* <div> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  format="yyyy/MM/dd"
                  value={this.state.date}
                  style={{ marginTop: 0 }}
                  onChange={(date) => {
                    this.setState({ date: date });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />

          <span style={{marginTop:"13px", marginLeft:"50px", display:"inline-flex"}}>
              <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.dayNight}
                    onChange={(event) => {
                      // console.log("New Plods Day Night Event ::>>  ", event.target);
                      this.setState({ dayNight: event.target.value });
                    }}
                  >
                    <MenuItem value={"Day"}>Day</MenuItem>
                    <MenuItem value={"Night"}>Night</MenuItem>
                  </Select>
                {/* <TextField
                  id="standard-basic"
                  label="Status"
                  inputRef={this.statusRef}
                /> */}
       </span> &nbsp;&nbsp;&nbsp;

             <span >
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={rig}
                  defaultValue=""
                  onChange={(event) => {
                    this.setState({
                      rig: event.target.value,
                      rigID: event.target.value,
                    });
                  }}
                >
                  {rigs.map((item) => (
                    <MenuItem key={item.ID} value={item.ID}>
                      {item.RigName}
                    </MenuItem>
                  ))}
                </Select>
                </span>

                <Grid xs={6}>
                <TextField
                  id="standard-basic"
                  label="Status"
                  inputRef={this.statusRef}
                />
                </Grid>
                {/* </div> */}
              </MuiPickersUtilsProvider>
            </div>
            {/* <div className={classes.column}> */}
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              {/* <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Time picker"
                  value={this.state.date}
                  onChange={(date) => {
                    this.setState({ date: date });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                /> */}
        
         
              
              {/* </MuiPickersUtilsProvider> */}
            {/* </div> */}
            {/* <div className={clsx(classes.column, classes.helper)}>
              <Typography variant="caption">
                Select your destination of choice
                <br />
                <a
                  href="#secondary-heading-and-columns"
                  className={classes.link}
                >
                  Learn more
                </a>
              </Typography>
            </div> */}
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button size="small">Cancel</Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                if (siteID != 0) {
                  if (rigID != 0) {
                    console.log("Ids:::>>>>  ");
                    this.savePlod();
                  }
                }
              }}
            >
              Save
            </Button>
          </AccordionActions>
        </Accordion>

        {this.state.shiftId && (
          <EplodEntityTable
            greatGrandParentEntity={{
              name: "EA_MiningCompanys",
              id: this.state.selectedMiningCompany,
            }}
            grandParentEntity={{  
              name: "EC_Contracts",
              id: this.state.selectedContract,
            }}
              eaChildren={{
                name:"SH_Sites",
                id: this.state.siteID,
              }}
            parentEntity={{
              name: "SH_Shifts",
              id: this.state.shiftId,
            }}
            entity="SH_Tasks"
            contract={"ConTask"}
            columns={[
              { title: "ID", field: "ID", hidden: true },
              {
                title: "Name",
                field: "EA_Task",
                fullWidth: true,
                width: "100%",
                defaultSort: "asc",
                editComponent: ({ value, onChange, rowData }) => (
                  <FormControl
                    variant="outlined"
                    className={classes.FormControl}
                    hiddenLabel={true}
                    fullWidth={true}
                  >
                    <Select
                      native
                      value={value}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                    >
                       {this.state.tasksList.map((tasks) => (
                              <option 
                              key={tasks.ID} 
                              value={tasks.ID}
                              >
                                {tasks.TaskName}
                                </option>
                       ))}
                    </Select>
                  </FormControl>
                ),
                // lookup: Object.fromEntries(
                //   this.state.tasks.map((t) => [t.ID, t.TaskName])
                // ),
              },
              {
                title: "Hours",
                field: "Hours",
                fullWidth: true,
                width: "100%",
                defaultSort: "asc",
                // lookup: Object.fromEntries(
                //   this.state.tasks.map((t) => [t.ID, t.TaskName])
                // ),
              },
            ]}
            title="Task Allocation"
           
          />
        )}
&nbsp;&nbsp;&nbsp;

 {this.state.shiftId && (
          <EplodEntityTable
            greatGrandParentEntity={{
              name: "EA_MiningCompanys",
              id: this.state.selectedMiningCompany,
            }}
            grandParentEntity={{
              name: "EC_Contracts",
              id: this.state.selectedContract,
            }}
            eaChildren={{
              name:"SH_Sites",
              id: this.state.siteID,
            }}
            parentEntity={{
              name: "SH_Shifts",
              id: this.state.shiftId,
            }}
            entity="SH_Consumables"
            contract={"ConConsumables"}
            columns={[
              { title: "ID", field: "ID", hidden: true },
              {
                title: "Name",
                field: "EA_Consumable",
                fullWidth: true,
                width: "50%",
                defaultSort: "asc",

                editComponent: ({ value, onChange, rowData }) => (
                  <FormControl
                    variant="outlined"
                    className={classes.FormControl}
                    hiddenLabel={true}
                    fullWidth={true}
                  >
                    <Select
                      native
                      value={value}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                    >
                       {this.state.consumablesList.map((consumables) => (
                              <option 
                              key={consumables.ID} 
                              value={consumables.ID}
                              >
                                {consumables.ConsumableName}
                                </option>
                       ))}
                    </Select>
                  </FormControl>
                ),

                // lookup: Object.fromEntries(
                //   this.state.tasks.map((t) => [t.ID, t.TaskName])
                // ),
              },
              {
                title: "Quantity",
                field: "Quantity",
                fullWidth: true,
                width: "100%",
                defaultSort: "asc",

                // lookup: Object.fromEntries(
                //   this.state.tasks.map((t) => [t.ID, t.TaskName])
                // ),
              },
            ]}
            title="Consumables"
           

          />
        )}

        {/*<EplodEntityTable greatGrandParentEntity={{name: "EA_MiningCompanys", id: this.state.selectedMiningCompany}} grandParentEntity={{name: "EC_Contracts", id: this.state.selectedContract}} parentEntity={{name: "SH_Contract_Shifts", id: this.state.shiftId}} entity="EA_Rigs" columns={*/}
        {/*    [*/}
        {/*        { title: 'ID', field: 'Link_Value.ID', hidden: true},*/}
        {/*        { title: 'Name', field: 'Link_Value.ID', lookup: Object.fromEntries(this.state.rigs.map(r => ([r.ID, r.ConsumableName   ]))) },*/}
        {/*    ]*/}
        {/*} title="Consumables" />*/}
        <Backdrop
          className={classes.backdrop}
          open={this.state.showBackdrop}
          onClick={() => this.setState({ showBackdrop: true })}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NewPlod);
