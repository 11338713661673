import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import EplodEntityTable from "./ListsComponents/EplodEntityTable";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import globals from "../globals";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormControl } from "@material-ui/core";
import EplodEntityTableBits from "./ListsComponents/EplodEntityTableBits";

const styles = (theme) => ({
  rows: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  buttonGroup: {
    // marginRight: theme.spacing(1),
  },
  progress: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  divAsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 300,
  },
});

class InvoicingComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { theme, classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" gutterBottom>
              Invoicing
            </Typography>

            <div>
              <TextField
                className={classes.row}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
                multiline
                rows={5}
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

class RateComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { theme, classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" gutterBottom>
              Rates
            </Typography>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

class FixedFeesComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { theme, classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{ padding: theme.spacing(2) }}>
            <Typography variant="h6" gutterBottom>
              Fixed Fees
            </Typography>
            <div style={{ marginTop: theme.spacing(2) }}>
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: theme.spacing(2) }}
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const Invoicing = withStyles(styles, { withTheme: true })(InvoicingComponent);
const Rate = withStyles(styles, { withTheme: true })(RateComponent);
const FixedFees = withStyles(styles, { withTheme: true })(FixedFeesComponent);

class ContractComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      miningCompanies: [],
      selectedMiningCompany: null,
      selectedContract: null,
      tasksList: [],
      rigsList: [],
      consumablesList: [],
      drillingTypesList: [],
      locationsList: [],
      selectedDrillingType: null,
      selectedDrillingTypeName: null,
      bitSizeList: [],
   
    };

    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/~GetAll/`;
    // const url = `${globals.host}ID_22/EA_MiningCompanys/~GetAll/`;

    console.log("ContractComponent--------->>>", props);
    fetch(url, {
      method: "POST",
    })
      .then((response) => {
        this.setState({
          loading: false,
          // miningCompanies: result,
          // selectedMiningCompany: result[0].ID,
        });
        // console.log("::::: ContractComponent Response>>>>>  ", response);
        return response.json();
      })
      .then((result) => {
        // console.log("::::: ContractComponent >>>>>  ", result);
        if (
          result != null &&
          result != undefined &&
          result != "" &&
          result.length > 0
        ) {
          this.setState({
            loading: false,
            miningCompanies: result,
            selectedMiningCompany: result[0].ID,
          });
        } else {
          this.setState({
            loading: false,
            miningCompanies: [],
          });
        }
      });
  }

  getBitSizeList() {
    console.log(this.state.selectedDrillingType);
    var url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${this.state.selectedContract}/EA_DrillingTypeP/ID_${this.state.selectedDrillingType}/EA_Bits/~GetChoices`;
    // var url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${this.state.selectedContract}/EA_PersonnelP/ID_${this.state.operatorList}/PersonnelType/ID_${this.state.operatorTypeList}/~SetLinkTo`
    fetch(url, {
      method: "post",
    }).then(async (response) => {
      var jsonData = await response.json();
      //  console.log("getBitSizeList-------->>>>",jsonData);

      if (jsonData.length) {
        this.setState({
          bitSizeList: [{ ID: -1, Size_: "--Select--" }, ...jsonData],
        });
      }
    });
  }

  componentDidMount() {
    this.refreshTasksList();
    this.refreshRigsList();
    this.refreshConsumablesList();
    this.refreshDrillingTypesList();
    this.refreshOperatorList();
    this.refreshOperatorTypeList();
    this.refreshLocationsList();
  }
  // componentDidUpdate(prevProps) {
  //   console.log(this.props)
  //   console.log(prevProps)
  //   if (this.props.selectedTab !== prevProps.selectTab) {
  //     this.refreshDrillingTypesList();
  //   }
  // }
  refreshTasksList() {
    var url = `${globals.host}ID_${global.Idx}/EA_Tasks/~GetAll/`;
    fetch(url, {
      method: "POST",
    }).then(async (response) => {
      var jsonData = await response.json();
      if (response.status == 200) {
        this.setState({
          tasksList: [{ ID: -1, TaskName: "- Select -" }, ...jsonData],
        });
      }
    });
  }

  refreshRigsList() {
    var url = `${globals.host}ID_${global.Idx}/EA_Rigs/~GetAll/`;
    fetch(url, {
      method: "POST",
    }).then(async (response) => {
      var jsonData = await response.json();
      if (response.status == 200) {
        this.setState({
          rigsList: [{ ID: -1, RigName: "- Select -" }, ...jsonData],
        });
      }
    });
  }
  refreshConsumablesList() {
    var url = `${globals.host}ID_${global.Idx}/EA_Consumables/~GetAll/`;

    fetch(url, {
      method: "POST",
    }).then(async (response) => {
      var jsonData = await response.json();
      if (response.status == 200) {
        this.setState({
          consumablesList: [
            { ID: -1, ConsumableName: "- Select -" },
            ...jsonData,
          ],
        });
      }
    });
  }
  refreshDrillingTypesList() {
    var url = `${globals.host}ID_${global.Idx}/EA_DrillingTypes/~GetAll/`;
    fetch(url, {
      method: "POST",
    }).then(async (response) => {
      var jsonData = await response.json();
      if (response.status == 200) {
        this.setState({
          drillingTypesList: [
            { ID: -1, DrillingType: "Select -", DrillingTypeCode: "" },
            ...jsonData,
          ],
        });
      }
    });
    // this.getBitTypeList();
    // this.getBitSizeList();
  }

  refreshOperatorList() {
    var url = `${globals.host}ID_${global.Idx}/EA_Personnels/~GetAll/`;

    fetch(url, {
      method: "POST",
    }).then(async (response) => {
      var jsonData = await response.json();
      if (response.status == 200) {
        this.setState({
          operatorList: [{ ID: -1, OperatorName: "- Select -" }, ...jsonData],
        });
      }
    });
  }

  refreshOperatorTypeList() {
    var url = `${globals.host}ID_${global.Idx}/EA_PersonnelTypes/~GetAll/`;
    fetch(url, {
      method: "POST",
    }).then(async (response) => {
      var jsonData = await response.json();
      if (response.status == 200) {
        this.setState({
          operatorTypeList: [
            { ID: -1, OperatorTypeName: "- Select -" },
            ...jsonData,
          ],
        });
      }
    });
  }

  refreshLocationsList() {
    var url = `${globals.host}ID_${global.Idx}/EA_Locations/~GetAll/`;
    fetch(url, {
      method: "post",
    }).then(async (response) => {
      var jsonData = await response.json();
      if (response.status == 200) {
        this.setState({
          locationsList: [{ ID: -1, LocationName: "- Select -" }, ...jsonData],
        });
      }
    });
  }

  selectChange = (event) => {
    // console.log(event.target.value);
    this.setState({ selectedMiningCompany: event.target.value });
  };

  render() {
    const { classes, theme, selectedTab } = this.props;
    if (this.state.loading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }
    return (
      <div style={{ height: "100vh" }}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.rows}>
          {/* <Link color="inherit" href="/" onClick={this.handleClick}>
            Material-UI
          </Link>
          <Link
            color="inherit"
            href="/getting-started/installation/"
            onClick={this.handleClick}
          >
            Core
          </Link> */}
          <Typography color="textPrimary">Eplod III</Typography>
        </Breadcrumbs>
        {/* <Button
          className={classes.buttonGroup}
          variant="contained"
          color="primary"
        >
          New Contract
        </Button> */}
        {/* <Button
          className={classes.buttonGroup}
          variant="contained"
          color="primary"
        >
          Filter
        </Button> */}
        <div className="div-as-row">
          {/* <div width="40vh"> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.selectedMiningCompany}
            onChange={this.selectChange}
          >
            {this.state.miningCompanies.map((m, i) => (
              <MenuItem key={i} value={m.ID}>
                {m.MiningCompanyName}
              </MenuItem>
            ))}
          </Select>
          {/* </div> */}
          {/* <div width="40vh"> */}
          <Button
            className={classes.buttonGroup}
            variant="contained"
            color="primary"
          >
            New Contract
          </Button>
          {/* </div> */}
        </div>
        {this.state.selectedMiningCompany && (
          <div className={classes.rows}>
            <EplodEntityTable
              parentEntity={{
                name: "EA_MiningCompanys",
                id: this.state.selectedMiningCompany,
              }}
              entity={"EC_Contracts"}
              title={"Contracts"}
              columns={[
                { title: "ID", field: "ID", hidden: true },
                {
                  title: "Name",
                  field: "ContractName",
                  fullWidth: true,
                  width: "70%",
                  defaultSort: "asc",
                },
              ]}
              onRowClick={(id) => {
                this.setState({ selectedContract: id });
              }}
            />
          </div>
        )}
        {!this.state.selectedMiningCompany && <CircularProgress />}
        {this.state.selectedContract && (
          <>
            <Paper className={classes.rows}>
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) => this.props.switchTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Tasks" />
                <Tab label="Rigs" />
                <Tab label="Consumables" />
                <Tab label="Drilling Types" />
                <Tab label="SITES" />
                <Tab label="Operators" />
              </Tabs>
            </Paper>
            <div style={{ padding: theme.spacing(1) }} className={classes.rows}>
              {!this.state.selectedContract && selectedTab === 0 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}
              {this.state.selectedContract && selectedTab === 0 && (
                <EplodEntityTable
                  grandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  parentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  entity="EC_ContractTasks"
                  contract={"Tasks"}
                  columns={[
                    { title: "ID", field: "ID", hidden: true },
                    {
                      title: "Name",
                      field: "TaskName",
                      fullWidth: true,
                      width: "100%",
                      defaultSort: "asc",
                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.FormControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.tasksList.map((tasks) => (
                              <option 
                              key={tasks.ID} 
                              value={tasks.ID}
                              >
                                {tasks.TaskName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    },
                  ]}
                  title="Task Allocation"
                />
              )}
              
              {!this.state.selectedContract && selectedTab === 1 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}
              {this.state.selectedContract && selectedTab === 1 && (
                <EplodEntityTable
                  grandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  parentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  entity="EC_ContractRigs"
                  contract={"Rigs"}
                  columns={[
                    { title: "ID", field: "ID", hidden: true },
                    {
                      title: "Name",
                      field: "RigName",
                      fullWidth: true,
                      width: "100%",
                      defaultSort: "asc",
                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.FormControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.rigsList.map((rigs) => (
                              <option key={rigs.ID} value={rigs.ID}>
                                {rigs.RigName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    },
                  ]}
                  title="Rigs"
                />
              )}
              {!this.state.selectedContract && selectedTab === 2 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}
              {this.state.selectedContract && selectedTab === 2 && (
                <EplodEntityTable
                  grandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  parentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  entity="EC_ContractConsumables"
                  contract={"Consumables"}
                  columns={[
                    { title: "ID", field: "ID", hidden: true },
                    {
                      title: "Name",
                      field: "ConsumableName",
                      fullWidth: true,
                      width: "100%",
                      defaultSort: "asc",

                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.FormControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.consumablesList.map((consumables) => (
                              <option
                                key={consumables.ID}
                                value={consumables.ID}
                              >
                                {consumables.ConsumableName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    },
                  ]}
                  title="Consumables"
                />
              )}

              {!this.state.selectedContract && selectedTab === 4 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}
              {this.state.selectedContract && selectedTab === 4 && (
                <EplodEntityTable
                  grandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  parentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  entity="EC_ContractSite"
                  contract={"Sites"}
                  columns={[
                    { title: "ID", field: "ID", hidden: true },
                    {
                      title: "Name",
                      field: "SiteName",
                      defaultSort: "asc",
                    },
                    {
                      title: "Location",
                      field: "LocationName",
                      fullWidth: true,
                      width: "100%",
                      defaultSort: "asc",
                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.FormControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.locationsList.map((location) => (
                              <option key={location.ID} value={location.ID}>
                                {location.LocationName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    },
                  ]}
                  title="Sites"
                />
              )}

              {!this.state.selectedContract && selectedTab === 5 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}
              {this.state.selectedContract && selectedTab === 5 && (
                <EplodEntityTable
                  grandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  parentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  entity="EA_PersonnelP"
                  contract={"Operators"}
                  columns={[
                    { title: "ID", field: "ID", hidden: true },
                    { title: "OperationTypeID", field: "OperationTypeID", hidden: true },
                    {
                      title: "Name",
                      field: "OperatorName",
                      fullWidth: true,
                      width: "50%",
                      editable: 'onAdd',
                      defaultSort: "asc",
                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.FormControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.operatorList.map((location) => (
                              <option key={location.ID} value={location.ID}>
                                {location.FirstName} { location.LastName} 
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    },
                    {
                      title: "Operator Type",
                      field: "OperatorTypeName",
                      fullWidth: true,
                      width: "50%",
                      defaultSort: "asc",
                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.FormControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.operatorTypeList.map((location) => (
                              <option key={location.ID} value={location.ID}>
                                {location.PersonnelType}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    },
                  ]}
                  title="Operators"
                />
              )}

              {!this.state.selectedContract && selectedTab === 3 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}
              {this.state.selectedContract && selectedTab === 3 && (
                <EplodEntityTable
                  grandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  parentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  entity="EA_DrillingTypeP"
                  contract={"DrillingType"}
                  columns={[
                    { title: "ID", field: "ID", hidden: true, width: "100%" },
                    {
                      title: "Name",
                      field: "DrillingType",
                      fullWidth: true,
                      width: "100%",
                      defaultSort: "asc",
                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.FormControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.drillingTypesList.map(
                              (drillingType) => (
                                <option
                                  key={drillingType.ID}
                                  value={drillingType.ID}
                                >
                                  {drillingType.DrillingTypeCode +
                                    " - " +
                                    drillingType.DrillingType}
                                </option>
                              )
                            )}
                          </Select>
                        </FormControl>
                      ),
                    },
                  ]}
                  title="Drilling Type"
                  refreshBitData={this.refreshBitData}
                  onRowClick={(value, rowdata) => {
                    console.log("Clicked row ID::::::>>>>> ", rowdata);
                    console.log("Clicked row ID::::::>>>>> ", value);
                    this.setState({
                      selectedDrillingType: value,
                      selectedDrillingTypeName: rowdata.DrillingType,
                    });
                    if (this.state.selectedDrillingType) {
                      this.getBitSizeList();
                    }
                    // console.log({selectedContract: value,  selectedContract:rowdata. DrillingType});
                  }}
                />
              )}
            </div>

            <div style={{ padding: theme.spacing(1) }} className={classes.rows}>
              {selectedTab === 3 && this.state.selectedDrillingType !== null && (
                // <EplodEntityTable
                //   parentEntity={
                //     name: "EA_Manufacturers",
                //     id: this.state.selectedManufacturer,
                //   }}
                //   entity="EA_Bits"
                //   columns={[
                //     { title: "ID", field: "ID", hidden: true },
                //     { title: "Name", field: "BitName" },
                //     {
                //       title: "Bit Type",
                //       field: "ID_EA_BitType",
                //       // lookup: Object.fromEntries(
                //       //   this.props.bitTypes.map((t) => [t.ID, t.BitType])
                //       // ),
                //     },
                //     {
                //       title: "Bit Size",
                //       field: "ID_EA_BitSize",
                //       // lookup: Object.fromEntries(
                //       //   this.props.bitSizes.map((t) => [t.ID, t.Size_])
                //       // ),
                //     },
                //   ]}
                //   title="Bits"
                // />
                <EplodEntityTableBits
                  // entity={"EA_Bits"}
                  grandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  parentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  contract={"DrillingType"}
                  entity={{
                    name: "EA_DrillingTypeP",
                    id: this.state.selectedDrillingType,
                  }}
                  columns={[
                    // { title: "Name", field: "BitName",width:"100%" },
                    {
                      title: "Name",
                      field: "Name",
                      width: "100%",
                      defaultSort: "asc",
                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.bitSizeList.map((bitType) => (
                              <option
                                key={bitType.ID}
                                value={bitType.ID}
                                // value={bitType.BitType}
                              >
                                {bitType.BitDescription}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    },
                    // {
                    //   title: "Manufacturer",
                    //   field: "ManufacturerName",
                    //   fullWidth: true, width: "100%",

                    //   editComponent: ({ value, onChange, rowData }) => (
                    //     <FormControl
                    //       variant="outlined"
                    //       className={classes.formControl}
                    //       hiddenLabel={true}
                    //       fullWidth={true}
                    //     >

                    //       <Select
                    //         native
                    //         value={value}
                    //         onChange={(event) => {
                    //           onChange(event.target.value);
                    //         }}
                    //       >
                    //         {this.state.manufacturerList.map((manufacturer) => (
                    //           <option
                    //             key={manufacturer.ID}
                    //             value={manufacturer.ID}
                    //           >
                    //             {manufacturer.ManufacturerName}
                    //           </option>
                    //         ))}
                    //       </Select>
                    //     </FormControl>
                    //   ),
                    // },

                    { title: "ID", field: "ID", hidden: true },
                  ]}
                  title={`Bits  - ${this.state.selectedDrillingTypeName}`}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ContractComponent);
