import React from "react";
import { forwardRef } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import RefreshIcon from "@material-ui/icons/Refresh";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import globals from "../../globals";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import _ from "lodash";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: green[500],
    },
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  RefreshIcon: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const styles = (theme) => ({
  rows: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
});
class EplodEntityTableBits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      anchorEl: null,
      pageSize: 5,
      orderDirection: "asc",
    };
    this.tableRef = React.createRef();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.parentEntity)
      if (prevProps.parentEntity.id !== this.props.parentEntity.id)
        this.tableRef.current && this.tableRef.current.onQueryChange();

    if (prevProps.grandParentEntity)
      if (prevProps.grandParentEntity.id !== this.props.grandParentEntity.id)
        this.tableRef.current && this.tableRef.current.onQueryChange();

    if (prevProps.greatGrandParentEntity)
      if (
        prevProps.greatGrandParentEntity.id !==
        this.props.greatGrandParentEntity.id
      )
        this.tableRef.current && this.tableRef.current.onQueryChange();

    if (prevProps.entity.id !== this.props.entity.id)
      this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  addNewItem(data, manufacturerID, resolve) {
    setTimeout(() => {
      resolve();
      var url2 = `${globals.host}ID_${global.Idx}/EA_Manufacturers/ID_${manufacturerID}/EA_Bits/`;

      var lastPathComponent = "~AddItem";
      var finalUrl = url2 + lastPathComponent;
      var finalData = JSON.stringify(data);

      fetch(finalUrl, {
        method: "post",
        body: finalData,
      })
        .then(async (response) => {
          return await response.json();
        })
        .then((result) => {
          return result;
        });
    }, 600);
  }
  render() {
    const { classes, theme } = this.props;
    console.log(this.props);
    var url = `${globals.host}ID_${global.Idx}/${this.props.entity.name}`;
    if (this.props.parentEntity) {
      url = `${globals.host}ID_${global.Idx}/${this.props.parentEntity.name}/ID_${this.props.parentEntity.id}/${this.props.entity}/`;
    }
    if (this.props.parentEntity && this.props.grandParentEntity) {
      url = `${globals.host}ID_${global.Idx}/${this.props.grandParentEntity.name}/ID_${this.props.grandParentEntity.id}/${this.props.parentEntity.name}/ID_${this.props.parentEntity.id}/${this.props.entity.name}/`;
    }
    if (
      this.props.parentEntity &&
      this.props.grandParentEntity &&
      this.props.greatGrandParentEntity
    ) {
      url = `${globals.host}ID_${global.Idx}/${this.props.greatGrandParentEntity.name}/ID_${this.props.greatGrandParentEntity.id}/${this.props.grandParentEntity.name}/ID_${this.props.grandParentEntity.id}/${this.props.parentEntity.name}/ID_${this.props.parentEntity.id}/${this.props.entity}/`;
    }
    return (
      <div>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>Profile</MenuItem>
          <MenuItem onClick={this.handleClose}>My account</MenuItem>
          <MenuItem onClick={this.handleClose}>Logout</MenuItem>
        </Menu>
        <MaterialTable
          title={this.props.title}
          columns={this.props.columns}
          icons={tableIcons}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  var lastPathComponent = `/ID_${this.props.entity.id}/EA_Bits/~AddItem/`;
                  if (
                    this.props.contract &&
                    this.props.contract == "DrillingType"
                  ) {
                    lastPathComponent = `ID_${this.props.entity.id}/EA_Bits/~AddLinkTo`;
                  } else if (this.props.SignOffComponent) {
                  } else if (this.props.grandParentEntity) {
                    lastPathComponent = "/EA_Bits/~AddItem/";
                  }
                  let data = newData;
                  console.log(data, "------------------------------");
                  if (this.props.contract) {
                    if (this.props.contract == "Tasks") {
                      data = {
                        EA_Task: [
                          { Link_Value: { ID: Number(data.TaskName) } },
                        ],
                      };
                    } else if (this.props.contract == "Rigs") {
                      data = {
                        EA_Rig: [{ Link_Value: { ID: Number(data.RigName) } }],
                      };
                    } else if (this.props.contract == "Consumables") {
                      data = {
                        EA_Consumable: [
                          { Link_Value: { ID: Number(data.ConsumableName) } },
                        ],
                      };
                    } else if (this.props.contract == "DrillingType") {
                      // console.log(data,'=========================')
                      data = {
                        Link_Value: { ID: Number(data.Name) },
                      };
                    } else if (this.props.contract == "Sites") {
                      data = {
                        SiteName: data.SiteName,
                        EA_Location: [
                          { Link_Value: { ID: Number(data.LocationName) } },
                        ],
                      };
                    }
                  } else if (this.props.SignOffComponent) {
                    if (this.props.SignOffComponent == "BottomTable") {
                      // console.log("Sign Off Data 208:::::>>>> ", data);
                      data = {
                        EA_Task: [
                          { Link_Value: { ID: Number(data.TaskName) } },
                        ],
                        Hours: Number(data.Hours),
                      };
                    } else if (
                      this.props.SignOffComponent == "BottomTable_Consumables"
                    ) {
                      // console.log("Sign Off Data 208:::::>>>> ", data);
                      data = {
                        EA_Consumable: [
                          { Link_Value: { ID: Number(data.ConsumableName) } },
                        ],
                        Quantity: Number(data.Quantity),
                      };
                    }
                  } else if (this.props.bit == "Manufacturers") {
                    data = {
                      BitName: newData.BitName,
                      E_EA_BitSizes: [
                        { Link_Value: { ID: newData.E_EA_BitSizes } },
                      ],
                      E_EA_BitTypes: [
                        { Link_Value: { ID: newData.E_EA_BitTypes } },
                      ],
                    };
                  }
                  fetch(url + lastPathComponent, {
                    method: "post",
                    body: JSON.stringify(data),
                  })
                    .then((response) => {
                      console.log("Eplod Entity Table Data >>>>>1  ", response);

                      this.tableRef.current &&
                        this.tableRef.current.onQueryChange();
                      return response.json();
                    })
                    .then((result) => {
                      console.log("EplodEntityTableData---->>>>>2");

                      // if (this.props.refreshBitData) {
                      //   console.log("refreshBitData----------------->>>");
                      //   this.props.refreshBitData();
                      // }
                      return result;
                    });
                }, 600);
              }),
            // onRowUpdate: (newData, oldData) =>
            //   new Promise((resolve) => {

            //     setTimeout(() => {
            //       resolve();
            //       fetch(url + "~UpdateItem/", {
            //         method: "post",
            //         body: JSON.stringify(newData),
            //       })

            //         .then(async (response) => {
            //           // console.log(response.json());
            //           return await response.json();
            //         })
            //         .then((result) => {
            //           // console.log(result);
            //           if (result.Result === "Updated") {
            //             return result;
            //           }
            //         });
            //     }, 600);
            //   }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                var finalUrl = url + `/ID_${this.props.entity.id}/EA_Bits/`;

                if (this.props.contract == "DrillingType") {
                  finalUrl += "~RemoveLinkTo";
                  oldData = {
                    Link_Value: {
                      ID: oldData.ID,
                    },
                  };
                } else if (this.props.bit == "Manufacturers") {
                  finalUrl += "~DeleteItem/";
                  oldData = {
                    Link_Value: {
                      ID: oldData.ID,
                    },
                  };
                } else {
                  finalUrl += "~DeleteItem/";
                }
                setTimeout(() => {
                  resolve();
                  fetch(finalUrl, {
                    method: "post",
                    body: JSON.stringify(oldData),
                  })
                    .then(async (response) => {
                      // console.log(response.json());
                      return await response.json(oldData);
                    })
                    .then((result) => {
                      console.log(result);
                      if (result.Result === "Deleted") {
                        return result;
                      }
                    });
                }, 600);
              }),
          }}
          tableRef={this.tableRef}
          data={(query) =>
            new Promise((resolve, reject) => {
              // console.log(url);
              // url += 'per_page=' + query.pageSize
              // url += '&page=' + (query.page + 1)
              let data = null;
              if (this.props.contract) {
                if (this.props.contract == "DrillingType") {
                  data = `{
                      EA_DrillingTypeP(ID:${this.props.entity.id}) {
                        ID
                        Link_Value(){
                            ID
                            DrillingType
                            DrillingTypeCode
                            
                        }
                        EA_Bits(){
                            ID
                            ID_EA_BitSize
                            ID_EA_BitType
                            BitName
                            BitDescription         
                        }
                    } 
                
                  }
                  `;
                }
              } else {
                data = `{
                  EA_Manufacturers(ID:${this.props.entity.id}) {  	
                    ManufacturerName            
                      ID
                      EA_Bits(){        
                    ID  
                    ID_EA_BitSize
                    ID_EA_BitType
                    BitName
                      E_EA_BitSizes(){      
                          ID
                          Size_
                      }
                      E_EA_BitTypes(){    
                          ID  
                          BitType
                      }
                    }
                  }
                }`;
              }

              fetch(url + "/~GraphQL", { method: "POST", body: data })
                .then(async (response) => {
                  return await response.json();
                })
                .then((result) => {
                  this.setState({ data: result });

                  const index = query.pageSize * query.page;
                  const endIndex = query.pageSize * (query.page + 1);
                  var data = [];
                  if (Array.isArray(result["data"]["EA_Manufacturers"])) {
                    var dataArray = result["data"]["EA_Manufacturers"].slice(
                      index,
                      endIndex
                    );
                    for (var i = 0; i < dataArray.length; i++) {
                      for (var j = 0; j < dataArray[i].EA_Bits.length; j++) {
                        for (
                          var k = 0;
                          k < dataArray[i].EA_Bits[j].E_EA_BitSizes.length;
                          k++
                        ) {
                          data = [
                            ...data,
                            {
                              ID: dataArray[i].EA_Bits[j].ID,
                              ManufacturerName: dataArray[i].ManufacturerName,
                              BitName: dataArray[i].EA_Bits[j].BitName,
                              E_EA_BitSizes:
                                dataArray[i].EA_Bits[j].E_EA_BitSizes[k].Size_,
                              E_EA_BitTypes:
                                dataArray[i].EA_Bits[j].E_EA_BitTypes[k]
                                  .BitType,
                            },
                          ];
                        }
                      }
                    }
                    // dataArray.map(i => {
                    //   i.EA_Bits.map(j => {
                    //     j.
                    //   })
                    // })
                  }
                  if (Array.isArray(result["data"]["EA_DrillingTypeP"])) {
                    var dataArray = result["data"]["EA_DrillingTypeP"].slice(
                      index,
                      endIndex
                    );
                    for (var i = 0; i < dataArray.length; i++) {
                      for (var j = 0; j < dataArray[i]["EA_Bits"].length; j++) {
                        data = [
                          ...data,
                          {
                            ID: dataArray[i].EA_Bits[j].ID,
                            Name: dataArray[i].EA_Bits[j]["BitDescription"],
                          },
                        ];
                      }
                    }
                  }
                  return resolve({
                    data:   query.orderBy ? 
                    _.orderBy(
                       data,
                       [data => data[query.orderBy.field].toString().toLowerCase()],
                       [query.orderDirection == 'asc' ? "asc" : "desc"]
                     ) : data,
                    page: query.page,
                    totalCount: data.length,
                  });
                });
            })
          }
          onRowClick={(evt, selectedRow) => {
            this.setState({ selectedRow: selectedRow.tableData.id });
            if (this.props.onRowClick) this.props.onRowClick(selectedRow.ID);
          }}
          options={{
            sorting: true,
            rowStyle: (rowData) => ({
              backgroundColor:
                this.state.selectedRow === rowData.tableData.id
                  ? "#EEE"
                  : "#FFF",
            }),
            pageSize: this.state.pageSize,
            pageSizeOptions: [5, 10, 20],
            toolbar: true,
            paging: true,
            actionsColumnIndex: -1,
            thirdSortClick: false
            // search: false
          }}
          actions={[
            {
              icon: RefreshIcon,
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () =>
                this.tableRef.current && this.tableRef.current.onQueryChange(),
            },
          ]}
          onChangeRowsPerPage={(pageSize) => {
            this.setState({ pageSize: pageSize });
          }}
          onOrderChange={(colId, ord) => {
            this.setState({ orderDirection: ord == 'asc' ? 'asc' : 'desc' });
            console.log(
              "OnOrderChange: [ColId: " + colId + ", Dir: " + ord + "]"
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EplodEntityTableBits);
