import { createStore, combineReducers } from "redux";
import { rootReducer } from "./reducers";
import globals from "./globals";

export const initialState = {
  ui: {
    clients: {
      selected: true,
    },
    lists: {
      selected: false,
      selectedTab: 0,
    },
    rigs: {
      selected: false,
    },
    bits: {
      selected: false,
    },
    contracts: {
      selected: false,
      selectedTab: 0,
    },
    contract_cost: {
      selected: false,
      selectedTab: 7,
    },
    newPlod: {
      selected: false,
    },
    signOff: {
      selected: false,
    },
  },
  rigs: [],
  tasks: [],
  consumables: [],
};

// const reducers = combineReducers({...rootReducer})
// localStorage.clear();

// export const store = createStore(
//     rootReducer,
//     (localStorage['redux-store']) ?
//         JSON.parse(localStorage['redux-store']) :
//         initialState
// );

export const configureStoreAsync = () => {
  return new Promise((resolve) => {
    var initialStoreState = initialState; //default initial store state
    try {

      //do some async stuff here to manipulate initial state...like read from local disk etc.
      //This is again wrapped in its own Promises.api.eplod.redochre.cloud
      //fetch(`${globals.host}ID_x/~apiTest1/`, {method: 'post'})


      if(global['Idx']){
        fetch(`${globals.host}ID_${global.Idx}/~GetAll/`, { method: "post" })
        .then((response) => {
          var json = response.json();
          // console.log(
          //   ":::::: Store Test API URL >>>>>>  ",
          //   `${globals.host}ID_${global.Idx}/~apiTest1/`
          // );
          // console.log(":::::: Store Test API Response >>>>>>  ", json);
          return json;
        })
        .then((result) => {
          // console.log("", result);
          initialStoreState = {
            rigs: result.rigs,
            tasks: result.tasks,
            consumables: result.consumables,
            bitTypes: result.bitTypes,
            bitSizes: result.bitSizes,
            ui: initialState.ui,
          };
          const store = createStore(rootReducer, initialStoreState);
          resolve(store);
        });
      }else{
        throw new Error("global idx undefined.")
      }
    } catch (error) {
      //To do .... log error!
      // console.error(error);
      const store = createStore(rootReducer, initialState);
      // console.log(store.getState());
      resolve(store);
    }
  });
};
// console.log(store.getState());
//
// store.subscribe(() => {
//     localStorage['redux-store'] = JSON.stringify(store.getState());
//     console.log(store.getState())
// });
