import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { configureStoreAsync, store } from "./store";

import ApolloClient from "apollo-client";
import { ApolloProvider, createNetworkInterface } from "react";
import gql from "graphql-tag";
import { setContext } from "@apollo/client/link/context";

import userEvent from "@testing-library/user-event";

configureStoreAsync().then((result) => {
  const store = result;
  return ReactDOM.render(
    <Provider store={store}>
      <App store={store} />
    </Provider>,
    document.getElementById("root")
  );
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
