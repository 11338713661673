import C from "./constants";
import {initialState} from "./store";

export const clients = (state, action) => {
    switch (action.type) {
        case C.SWITCH_TAB:
            return {
                ...state,
                selected: action.selectedTab === 0
            }
        default:
            return state;
    }
}

export const lists = (state, action) => {
    switch (action.type) {
        case C.SWITCH_LIST_TAB:
            return {
                ...state,
                selectedTab: action.selectedTab
            }
        case C.SWITCH_TAB:
            return {
                ...state,
                selected: action.selectedTab === 1
            }
        default:
            return state;
    }
}

export const rigs = (state, action) => {
    switch (action.type) {
        case C.SWITCH_TAB:
            return {
                ...state,
                selected: action.selectedTab === 2
            }
        default:
            return state;
    }
}

export const bits = (state, action) => {
    switch (action.type) {
        case C.SWITCH_TAB:
            return {
                ...state,
                selected: action.selectedTab === 3
            }
        default:
            return state;
    }
}

export const contracts = (state, action) => {
    switch (action.type) {
        case C.SWITCH_TAB:
            return {
                ...state,
                selected: action.selectedTab === 4
            }
        case C.SWITCH_CONTRACT_TAB:
            return {
                ...state,
                selectedTab: action.selectedTab
            }
        default:
            return state;
    }
}
export const contract_cost = (state, action) => {
    switch (action.type) {
        case C.SWITCH_TAB:
            return {
                ...state,
                selected: action.selectedTab === 7
            }
            
        default:
            return state;
    }
}

export const newPlod = (state, action) => {
    switch (action.type) {
        case C.SWITCH_TAB:
            return {
                ...state,
                selected: action.selectedTab === 5
            }
        default:
            return state;
    }
}

export const signOff = (state, action) => {
    switch (action.type) {
        case C.SWITCH_TAB:
            return {
                ...state,
                selected: action.selectedTab === 6
            }
        default:
            return state;
    }
}

export const ui = (state, action) => {
    switch (action.type) {
        case C.SWITCH_LIST_TAB:
           return {
               ...state,
               lists: lists(state['lists'], action)
           }
        case C.SWITCH_CONTRACT_TAB:
            return {
                ...state,
                contracts: contracts(state['contracts'], action)
            }
        case C.SWITCH_TAB:
            return {
                ...state,
                clients: clients(state['clients'], action),
                lists: lists(state['lists'], action),
                rigs: rigs(state['rigs'], action),
                bits: bits(state['bits'], action),
                contracts: contracts(state['contracts'], action),
                contract_cost: contract_cost(state['contract_cost'], action),
                newPlod: newPlod(state['newPlod'], action),
                signOff: signOff(state['signOff'], action)
            }
        default:
            return state;
    }
}



export const rootReducer = (state, action) => {
    // console.log(state);
    // console.log(action);
    switch (action.type) {
        case C.SWITCH_LIST_TAB:
        case C.SWITCH_TAB:
        case C.SWITCH_CONTRACT_TAB:
            return {
                ...state,
                ui: ui(state['ui'], action)
            }
        default:
            return state;
    }
}