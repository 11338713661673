import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Drawer } from "./container";
import swal from "sweetalert";
import queryString from "query-string";
import { gql, useQuery } from "@apollo/client";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { configureStoreAsync } from "./store";
import globals from "./globals";

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      query: window.location.href,
      LoginToken: this.query,
      IdStatus: false,
    };
  }
  callfunc() {
    swal(
      "No token provided - please vist login.redochre.cloud to get started"
    ).then((value) => {
      window.location = `https://login.redochre.cloud`;
    });
  }

  LoggedInTokenApplicationApi() {
    const parsed = queryString.parse(window.location.search);

    // console.log("parsed------------>>>", parsed.LoginToken);

    fetch(
      "https://api.login.redochre.cloud/api/LoggedInToken/0/Application_/0",
      {
        method: "post",
        headers: {
          "Content-Type": "text/plain",
          LoginToken: parsed.LoginToken,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log("LoggedInTokenApplicationApi------", data.ID);
        this.ImpApis(data.ID);
      }).catch((e) => console.log(e));

    // fetch(
    //   "http://api.login.redochre.cloud/api/LoggedInToken/0/Application_/0",
    //   {
    //     method: "POST",
    //     headers: {
    //       "LoginToken": parsed.LoginToken,
    //       "Content-Type": "text/plain",
    //       'cache-control': "no-cache",
    //     },
    //     mode: "no-cors",
    //   }
    // ).then((data) => {
    //   console.log("json11------", data);
    // });

    // let d = await fetch(
    //   "http://api.login.redochre.cloud/api/LoggedInToken/0/Application_/0",
    //   {
    //     method: "POST",
    //     headers: {
    //       LoginToken: parsed.LoginToken,
    //     },
    //     mode: "no-cors",
    //   }
    // );
    // console.log(d);

    // let options = {
    //   method: "POST",
    //   url: "http://api.login.redochre.cloud/api/LoggedInToken/0/Application_/0",
    //   mode: "no-cors",
    //   headers: {
    //     LoginToken: parsed.LoginToken,
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   withCredentials: true,
    //   credentials: "same-origin",
    // };

    // let response = axios(options);
    // console.log("data------->>>", response.data);
  }

  ImpApis(Id) {
    fetch("https://api.eplod.redochre.cloud/api/Eplods/~GraphQL", {
      method: "POST",
      body: `
      {
        Eplods(ID_AP_Application:${Id}) {
             ID 
             ID_AP_Application
             Eplod_App_DrillingCompanyName
             }
     }
    `,
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("GraphQL-------->>>>" + data);

        //{ "data": {  "Eplods":  [{"ID":21,"ID_AP_Application":1040,"Eplod_App_DrillingCompanyName":"I am an eplod ok"}] }}

        if (data != null) {
          var a = data["data"]["Eplods"];

          if (a.length > 0) {
            var b = a[0]["ID"];

            console.log("Get Id from GraphQL---------->>>", b);
            global.Idx = b;

            this.setState({
              IdStatus: true,
            });
            if(global.Idx){
              configureStoreAsync();
              // console.log(global.Idx)
            }
          } else {
            // console.log("LogElseAPart----------->>>");
          }
        } else {
          // console.log("LogElseDataPart----------->>>");
        }
      });
  }

  componentDidMount() {
    {
      const parsed = queryString.parse(window.location.search);
      parsed.LoginToken ? this.LoggedInTokenApplicationApi() : this.callfunc();
    }
   
  }

  //    return <>{parsed.LoginToken ? <Drawer /> : this.callfunc()}</>;

  render() {
    return (
      <>
        {this.state.IdStatus == true && global.Idx != null ? (
          <Drawer />
        ) : (
          <CircularProgress />
        )}
      </>
    );
  }
}
