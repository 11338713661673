import React from "react";
import { forwardRef } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import RigsTable from "./ListsComponents/RigsTable";
import EplodEntityTable from "./ListsComponents/EplodEntityTable";

const styles = (theme) => ({
  rows: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
});

class ListComponents extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, theme, selectedTab } = this.props;
    // console.log(selectedTab);
    // console.log(this.props);
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" className={classes.rows}>
          {/* <Link color="inherit" href="/" onClick={this.handleClick}>
            Material-UI
          </Link>
          <Link
            color="inherit"
            href="/getting-started/installation/"
            onClick={this.handleClick}
          >
            Core
          </Link> */}
          <Typography color="textPrimary">Eplod III</Typography>
        </Breadcrumbs>
        <Paper className={classes.rows}>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => this.props.switchTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Operators" />
            <Tab label="Personnels Types" />
            <Tab label="Locations" />
            {/* <Tab label="Sites" /> */}
            <Tab label="Tasks" />
            <Tab label="Consumables" />
            <Tab label="Drilling Types" />
          </Tabs>
        </Paper>
        {/* <Button
          variant="contained"
          color="primary"
          classes={classes.button}
          endIcon={<SaveIcon />}
        >
          Export to excel
        </Button> */}
        <div className={classes.rows}>
          {selectedTab === 0 && (
            <EplodEntityTable
              entity={"EA_Personnels"}
              columns={[
              
                {
                  title: "First Name",
                  field: "FirstName",
                  width: "20%",
                  defaultSort: "asc",
                },
                {
                  title: "Last Name",
                  field: "LastName",
                  fullWidth: true,
                  width: "80%",
                  defaultSort: "asc",
                },
                { title: "ID", field: "ID", hidden: true },
              ]}
              title={"Personnels"}
            />
          )}
          {selectedTab === 1 && (
            <EplodEntityTable
              entity={"EA_PersonnelTypes"}
              columns={[       
                {
                  title: "PersonnelType",
                  field: "PersonnelType",
                  width: "80%",
                  defaultSort: "asc",
                  disableClick:true
                },
                { title: "ID", field: "ID", hidden: true },
                //   { title: "Last Name", field: "LastName", fullWidth: true, width: "80%",
                // },
              ]}
              title={"Personnels"}
            />
          )}
          {selectedTab === 2 && (
            <EplodEntityTable
              entity={"EA_Locations"}
              columns={[
                { title: "ID", field: "ID", hidden: true },
                {
                  title: "Name",
                  field: "LocationName",
                  fullWidth: true,
                  width: "100%",
                  defaultSort: "asc",
                },
              ]}
              title={"Place"}
            />
          )}
          {selectedTab === 3 && (
            <EplodEntityTable
              entity="EA_Tasks"
              columns={[
                { title: "ID", field: "ID", hidden: true },
                {
                  title: "Name",
                  field: "TaskName",
                  fullWidth: true,
                  width: "100%",
                  defaultSort: "asc",
                },
              ]}
              title="Tasks"
            />
          )}
          {selectedTab === 4 && (
            <EplodEntityTable
              entity="EA_Consumables"
              columns={[
                { title: "ID", field: "ID", hidden: true },
                {
                  title: "Name",
                  field: "ConsumableName",
                  fullWidth: true,
                  width: "100%",
                  defaultSort: "asc",
                },
              ]}
              title="Consumables"
            />
          )}
          {selectedTab === 5 && (
            <EplodEntityTable
              entity="EA_DrillingTypes"
              columns={[
                { title: "ID", field: "ID", hidden: true },
                {
                  title: "Code",
                  field: "DrillingTypeCode",
                  width: "30%",
                  defaultSort: "asc",
                },
                {
                  title: "Type",
                  field: "DrillingType",
                  fullWidth: true,
                  width: "70%",
                  defaultSort: "asc",
                },
              ]}
              title="Consumables"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ListComponents);
