import React from "react";
import { forwardRef } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import RigsTable from "./ListsComponents/RigsTable";
import EplodEntityTable from "./ListsComponents/EplodEntityTable";

const styles = (theme) => ({
  rows: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
});

class RigComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, theme, selectedTab } = this.props;
    // console.log(selectedTab);
    // console.log(this.props);
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" className={classes.rows}>
          {/* <Link color="inherit" href="/" onClick={this.handleClick}>
                        Material-UI
                    </Link>
                    <Link color="inherit" href="/getting-started/installation/" onClick={this.handleClick}>
                        Core
                    </Link> */}
          <Typography color="textPrimary">Eplod III</Typography>
        </Breadcrumbs>
        <Paper className={classes.rows}>
          <Tabs
            value={0}
            onChange={(event, newValue) => this.props.switchTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Rigs" />
          </Tabs>
        </Paper>
        {/* <Button
                    variant="contained"
                    color="primary"
                    classes={classes.button}
                    endIcon={<SaveIcon/>}
                >
                    Export to excel
                </Button> */}
        <div className={classes.rows}>
          <EplodEntityTable
            entity={"EA_Rigs"}
            columns={[
              { title: "ID", field: "ID", hidden: true },
              {
                title: "Rig Name",
                field: "RigName",
                fullWidth: true,
                width: "100%",
                defaultSort: "asc",
              },
            ]}
            title={"Rigs"}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RigComponent);
