var globals = {}
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
   // globals.host = 'http://localhost:44393/api/Eplods/'
   globals.host = 'https://api.eplod.redochre.cloud/api/Eplods/'
} else {
    // production code
    globals.host = 'https://api.eplod.redochre.cloud/api/Eplods/';

}

export default globals;