import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import Tooltip from "@material-ui/core/Tooltip";
import SendIcon from "@material-ui/icons/Send";
import DraftsIcon from "@material-ui/icons/Drafts";
import {
  Bits,
  Clients,
  Contracts,
  Lists,
  Plod,
  Rigs,
  SignOff,
  Contract_cost
} from "../container";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

class ResponsiveDrawer extends React.Component {
  
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      mobileOpen: false,
    };
  }
 

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const { classes, theme } = this.props;
    const { mobileOpen } = this.state;

    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Setup
            </ListSubheader>
          }
        >
          <Tooltip title="Lists" placement="right">
            <ListItem
              button
              selected={this.props.lists.selected}
              onClick={() => {
                this.props.switchTab(1);
              }}
            >
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary="Lists" />
            </ListItem>
          </Tooltip>

          <Tooltip title="Rigs" placement="right">
            <ListItem
              button
              selected={this.props.rigs.selected}
              onClick={() => {
                this.props.switchTab(2);
              }}
            >
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Rigs" />
            </ListItem>
          </Tooltip>

          <Tooltip title="Bits" placement="right">
            <ListItem
              button
              selected={this.props.bits.selected}
              onClick={() => {
                this.props.switchTab(3);
              }}
            >
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Bits" />
            </ListItem>
          </Tooltip>

          <Divider />
          <List>
            <Tooltip title="Clients" placement="right">
              <ListItem
                button
                selected={this.props.clients.selected}
                onClick={() => {
                  this.props.switchTab(0);
                }}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary="Clients" />
              </ListItem>
            </Tooltip>

            <Tooltip title="Contracts" placement="right">
              <ListItem
                button
                selected={this.props.contracts.selected}
                onClick={() => {
                  this.props.switchTab(4);
                }}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Contracts" />
              </ListItem>
            </Tooltip>
            
            <Tooltip title="Contract Cost" placement="right">
              <ListItem
                button
                selected={this.props.contract_cost.selected}
                onClick={() => {
                  this.props.switchTab(7);
                }}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Contract Cost" />
              </ListItem>
            </Tooltip>
          </List>
        </List>
        <Divider />
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              PLODs
            </ListSubheader>
          }
        >
          <Tooltip title="New Plod" placement="right">
            <ListItem
              button
              selected={this.props.newPlod.selected}
              onClick={() => {
                this.props.switchTab(5);
              }}
            >
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="New PLOD" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Review & Signoff" placement="right">
            <ListItem
              button
              selected={this.props.signOff.selected}
              onClick={() => {
                this.props.switchTab(6);
              }}
            >
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Review & Signoff" />
            </ListItem>
          </Tooltip>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Invoicing" />
          </ListItem>
        </List>
      </div>
    );

    const container =
      window !== undefined ? () => window.document.body : undefined;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              EPlod III
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.clients.selected && <Clients />}
          {this.props.lists.selected && <Lists />}
          {this.props.rigs.selected && <Rigs />}
          {this.props.bits.selected && <Bits />}
          {this.props.contracts.selected && <Contracts />}
          {this.props.contract_cost.selected && <Contract_cost />}
          {this.props.newPlod.selected && <Plod />}
          {this.props.signOff.selected && <SignOff />}
        </main>
      </div>
    );
  }
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
