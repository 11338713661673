import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import EplodEntityTable from "./ListsComponents/EplodEntityTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import globals from "../globals";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import AccordionActions from "@material-ui/core/AccordionActions";
import Backdrop from "@material-ui/core/Backdrop";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { FormControl } from "@material-ui/core";

const styles = (theme) => ({
  rows: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  buttonGroup: {
    marginRight: theme.spacing(1),
  },
  progress: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
});

class SignOffComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      miningCompanies: [],
      contracts: [],
      selectedMiningCompany: null,
      selectedContract: null,
      selectedRig: null,
      date: new Date(),
      shiftId: 0,
      showBackdrop: false,
      selectedTab: 0,

      rigs: [],
      consumables: [],
      tasks: [],

      selectedTaskID: 0,
    };
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/~GetAll/`;
    this.statusRef = React.createRef();

    fetch(url, { method: "post" })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log("Mining Companies :::>>>\n", result);
        if (
          result.length != 0 &&
          result != 0 &&
          result != undefined &&
          result != ""
        ) {
          console.log(
            "...........................................line 87",
            result
          );
          this.setState({
            loading: false,
            miningCompanies: result,
            selectedMiningCompany: result[0].ID,
          });
          this.selectChange({ target: { value: result[0].ID } });
        }
      });
  }

  selectChange = (event) => {
    this.setState({ selectedMiningCompany: event.target.value });
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${event.target.value}/EC_Contracts/~GetAll/`;
    fetch(url, { method: "post" })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log(result, "------------------------------");
        if (result.length !== 0) {
          this.setState({
            loading: false,
            contracts: result,
            selectedContract: result[0].ID,
          });
          this.contractSelectChange({
            target: { value: result[0].ID },
          });
        }
      });
  };

  contractSelectChange = (event) => {
    this.setState({ selectedContract: event.target.value });
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${event.target.value}/`;

    // let urlRIGS = url + "EA_Rigs/~GetAll/";
    // let urlRIGS = url + "EC_ContractRigs/~GetAll";
    let urlRIGS = url + "EC_ContractRigs/~GraphQL";
    const body = `{
                    EC_ContractRigs() {  	      
                        ID
                        EA_Rig(){        
                      ID
                      RigName
                    }
                    }
                  }`;
    fetch(urlRIGS, { method: "POST", body: body })
      .then(async (response) => {
        return await response.json();
      })
      .then((result) => {
        // console.log("EC_ContractRigs URL ::::::>>>>>>\n", urlRIGS);
        // console.log("EC_ContractRigs Response ::::::>>>>>>\n", result);
        console.log(result, "---signOFFcom");
        if (result.data) {
          const mappedResult = result.data.EC_ContractRigs.map((l) => ({
            ID: l.EA_Rig[0].ID,
            RigName: l.EA_Rig[0].RigName,
          }));
          if (mappedResult.length !== 0)
            this.setState({ loading: false, rigs: mappedResult });
        }

        // this.setState({ loading: false, rigs: result });
      });

    // let urlShift = url + "EA_Tasks/~GetAll/";
    let urlTasks = url + "EC_ContractTasks/~GetAll";
    fetch(urlTasks, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => {
        // console.log("EC_ContractTasks URL ::::>>>>\n", urlTasks);
        // console.log("EC_ContractTasks Response ::::>>>>\n", result);
        // const mappedResult = result.map((l) => ({
        //   ID: l.Link_Value.ID,
        //   TaskName: l.Link_Value.TaskName,
        // }));
        // if (mappedResult.length !== 0)
        //   this.setState({ loading: false, tasks: mappedResult });
      });

    // let urlConsumable = url + "EA_Consumables/~GetAll/";
    let urlConsumable = url + "EC_ContractConsumables/~GetAll/";
    fetch(urlConsumable, { method: "post" })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        // console.log("EC_ContractConsumables URL :::>>>\n", urlConsumable);
        // console.log("EC_ContractConsumables Response :::>>>\n", result);
        // const mappedResult = result.map((l) => ({
        //   ID: l.Link_Value.ID,
        //   ConsumableName: l.Link_Value.ConsumableName,
        // }));
        // if (mappedResult.length !== 0)
        // this.setState({ loading: false, consumables: mappedResult });
        // this.setState({ loading: false, consumables: result });
      });
  };

  rigSelectChange = (event) => {
    // console.log(event.target.value);
    this.setState({ selectedRig: event.target.value });
  };

  getTasksList = () => {
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${this.state.selectedContract}/EC_ContractTasks/~GraphQL/`;
    const body = `{
                    EC_ContractTasks() {  	      
                      ID
                      EA_Task(){        
                        ID
                        TaskName
                      }
                    }
                  }`;
    fetch(url, { method: "post", body: body })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result) {
          console.log("Task List 222 ::::>>>>  ", result);
          if (result.data) {
            if (result.data.EC_ContractTasks) {
              const mappedResult = result.data.EC_ContractTasks.map((l) => ({
                ID: l.EA_Task[0].ID,
                TaskName: l.EA_Task[0].TaskName,
              }));
              if (mappedResult.length !== 0)
                this.setState({
                  loading: false,
                  tasks: [{ ID: -1, TaskName: "- Select -" }, ...mappedResult],
                });
              console.log("Task List 222 ::::>>>>  ", url);
              console.log("Task List 222 ::::>>>>  ", mappedResult);
            }
          }
        }
      });
  };

  getConsumablesList = () => {
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${this.state.selectedContract}/EC_ContractConsumables/~GraphQL/`;
    const body = `{
                    EC_ContractConsumables() {  	      
                      ID
                      EA_Consumable(){        
                        ID
                        ConsumableName
                      }
                    }
                  }`;
    fetch(url, { method: "post", body: body })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.EC_ContractConsumables) {
              const mappedResult = result.data.EC_ContractConsumables.map(
                (l) => ({
                  ID: l.EA_Consumable[0].ID,
                  Name: l.EA_Consumable[0].ConsumableName,
                })
              );
              if (mappedResult.length !== 0)
                this.setState({
                  loading: false,
                  consumables: [
                    { ID: -1, Name: "- Select -" },
                    ...mappedResult,
                  ],
                });
              console.log("Consumables List 250 ::::>>>>  ", url);
              console.log("Consumables List 250 ::::>>>>  ", mappedResult);
            }
          }
        }
      });
  };

  savePlod = () => {
    this.setState({ showBackdrop: true });
    const url = `${globals.host}ID_${global.Idx}/EA_MiningCompanys/ID_${this.state.selectedMiningCompany}/EC_Contracts/ID_${this.state.selectedContract}/SH_Contract_Shifts/~AddItem/`;
    fetch(url, {
      method: "post",
      body: JSON.stringify({
        DayNight: "Day",
        Date: this.state.date,
        Status: this.statusRef.current.value,
        initialRigID: this.state.selectedRig,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ shiftId: result.ID });
        this.setState({ showBackdrop: false });
      });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.rows}>
          {/* <Link color="inherit" href="/" onClick={this.handleClick}>
            Material-UI
          </Link>
          <Link
            color="inherit"
            href="/getting-started/installation/"
            onClick={this.handleClick}
          >
            Core
          </Link> */}
          <Typography color="textPrimary">Eplod III</Typography>
        </Breadcrumbs>
        <Typography>Mining Companies</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.selectedMiningCompany}
          onChange={this.selectChange}
        >
          {this.state.miningCompanies.map((m, i) => (
            <MenuItem key={i} value={m.ID}>
              {m.MiningCompanyName}
            </MenuItem>
          ))}
        </Select>
        <Typography>Contracts</Typography>
        {this.state.selectedContract && (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.selectedContract}
            onChange={this.contractSelectChange}
          >
            {this.state.contracts.map((m, i) => (
              <MenuItem key={i} value={m.ID}>
                {m.ContractName}
              </MenuItem>
            ))}
          </Select>
        )}
        {this.state.selectedContract && (
          <EplodEntityTable
            grandParentEntity={{
              name: "EA_MiningCompanys",
              id: this.state.selectedMiningCompany,
            }}
            parentEntity={{
              name: "EC_Contracts",
              id: this.state.selectedContract,
            }}
            entity="SH_Shifts"
            SignOffComponent="MainTable"
            columns={[
              { title: "ID", field: "ID", hidden: true },
              { title: "Day or Night", field: "DayNight", defaultSort: "asc" },
              { title: "Date", field: "Date", defaultSort: "asc" },
              { title: "Status", field: "Status", defaultSort: "asc" },
              {
                title: "Rig",
                field: "initialRigID",
                defaultSort: "asc",
                editComponent: ({ value, onChange, rowData }) => (
                  <FormControl
                    variant="standard"
                    className={classes.formControl}
                    hiddenLabel={true}
                    fullWidth={true}
                  >
                    <Select
                      native
                      value={value}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                    >
                      {this.state.rigs.map((rig) => (
                        <option key={rig.ID} value={rig.ID}>
                          {rig.RigName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                ),
              },
            ]}
            title="Shifts / Eplods"
            onRowClick={(selectedId) => {
              this.setState({ shiftId: selectedId });
              this.getTasksList();
              this.getConsumablesList();
            }}
          />
        )}
        {this.state.shiftId !== 0 && (
          <>
            <Paper className={classes.rows}>
              <Tabs
                value={this.state.selectedTab}
                onChange={(event, newValue) => {
                  this.setState({ selectedTab: newValue });
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Tasks" />
                <Tab label="Consumables" />
                <Tab label="Personnel" />
                <Tab label="Drilling" />
              </Tabs>
            </Paper>
            <div style={{ padding: theme.spacing(1) }} className={classes.rows}>
              {!this.state.shiftId && this.state.selectedTab === 0 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}
              {this.state.shiftId && this.state.selectedTab === 0 && (
                <EplodEntityTable
                  greatGrandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  grandParentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  parentEntity={{
                    name: "SH_Shifts",
                    id: this.state.shiftId,
                  }}
                  entity="SH_Tasks"
                  SignOffComponent="BottomTable"
                  columns={[
                    { title: "ID", field: "Link_Value.ID", hidden: true },
                    {
                      title: "Name",
                      field: "TaskName",
                      defaultSort: "asc",
                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="standard"
                          className={classes.formControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.tasks.map((task) => (
                              <option key={task.ID} value={task.ID}>
                                {task.TaskName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    },
                    { title: "Hours", field: "Hours", defaultSort: "asc" },
                  ]}
                  title="Task Allocation"
                />
              )}
              {!this.state.shiftId && this.state.selectedTab === 1 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}

              {this.state.shiftId && this.state.selectedTab === 1 && (
                <EplodEntityTable
                  greatGrandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  grandParentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  parentEntity={{
                    name: "SH_Shifts",
                    id: this.state.shiftId,
                  }}
                  entity="SH_Consumables"
                  SignOffComponent="BottomTable_Consumables"
                  columns={[
                    { title: "ID", field: "Link_Value.ID", hidden: true },
                    {
                      title: "Name",
                      field: "ConsumableName",
                      width: "20%",
                      defaultSort: "asc",
                      editComponent: ({ value, onChange, rowData }) => (
                        <FormControl
                          variant="standard"
                          className={classes.formControl}
                          hiddenLabel={true}
                          fullWidth={true}
                        >
                          <Select
                            native
                            value={value}
                            onChange={(event) => {
                              onChange(event.target.value);
                            }}
                          >
                            {this.state.consumables.map((item) => (
                              <option key={item.ID} value={item.ID}>
                                {item.Name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      ),
                    },
                    {
                      title: "Qty",
                      field: "Quantity",
                      fullWidth: true,
                      width: "80%",
                      defaultSort: "asc",
                    },
                  ]}
                  title="Consumables"
                />
              )}
              {!this.state.shiftId && this.state.selectedTab === 2 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}
              {this.state.shiftId && this.state.selectedTab === 2 && (
                <EplodEntityTable
                  greatGrandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  grandParentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  parentEntity={{
                    name: "SH_Shifts",
                    id: this.state.shiftId,
                  }}
                  entity="SH_Tasks"
                  columns={[
                    { title: "ID", field: "Link_Value.ID", hidden: true },
                    {
                      title: "Name",
                      field: "Link_Value.ID",
                      fullWidth: true,
                      width: "80%",
                      defaultSort: "asc",
                      lookup: Object.fromEntries(
                        this.state.consumables.map((t) => [
                          t.ID,
                          t.ConsumableName,
                        ])
                      ),
                    },
                  ]}
                  title="Consumables"
                />
              )}
              {!this.state.shiftId && this.state.selectedTab === 3 && (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              )}
              {this.state.shiftId && this.state.selectedTab === 3 && (
                <EplodEntityTable
                  greatGrandParentEntity={{
                    name: "EA_MiningCompanys",
                    id: this.state.selectedMiningCompany,
                  }}
                  grandParentEntity={{
                    name: "EC_Contracts",
                    id: this.state.selectedContract,
                  }}
                  parentEntity={{
                    name: "SH_Contract_Shifts",
                    id: this.state.shiftId,
                  }}
                  entity="EA_Consumables"
                  columns={[
                    { title: "ID", field: "Link_Value.ID", hidden: true },
                    {
                      title: "Name",
                      field: "Link_Value.ID",
                      fullWidth: true,
                      width: "100%",
                      defaultSort: "asc",
                      lookup: Object.fromEntries(
                        this.state.consumables.map((t) => [
                          t.ID,
                          t.ConsumableName,
                        ])
                      ),
                    },
                  ]}
                  title="Consumables"
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SignOffComponent);
