import React from "react";
import { forwardRef } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import RefreshIcon from "@material-ui/icons/Refresh";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import globals from "../../globals";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import _ from "lodash";
import orderBy from 'lodash/orderBy';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: green[500],
    },
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  RefreshIcon: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};



const styles = (theme) => ({
  rows: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
});


class EplodEntityTable extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      columns: [
        { title: "ID", field: "ID", hidden: true },
        { title: "Name", field: "TaskName" },
      ],
      filterMethod : (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
      },
      selectedRow: null,
      anchorEl: null,
      pageSize: 5,
      isLoading: false,
      orderDirection: "asc",
    };

    this.tableRef = React.createRef();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // this.tableRef.current && this.tableRef.current.onQueryChange();

    if (prevProps.parentEntity)
      if (prevProps.parentEntity.id !== this.props.parentEntity.id)
        this.tableRef.current && this.tableRef.current.onQueryChange();

    if (prevProps.grandParentEntity)
      if (prevProps.grandParentEntity.id !== this.props.grandParentEntity.id)
        this.tableRef.current && this.tableRef.current.onQueryChange();

    if (prevProps.greatGrandParentEntity)
      if (
        prevProps.greatGrandParentEntity.id !==
        this.props.greatGrandParentEntity.id
      )
        this.tableRef.current && this.tableRef.current.onQueryChange();
  }
  onGetData(query,url){
    return  new Promise((resolve, reject) => {
        let body = null;
        let lastPath = "";
        if (this.props.contract) {
          if (this.props.contract == "Tasks") {
            lastPath = "~GraphQL";
            body = `{
              EC_ContractTasks() {  	      
                      EA_Task(){        
                              ID
                            TaskName
                     }
               }
         }`;

          } else if (this.props.contract == "ConTask") {
          lastPath = "~GraphQL";
          body = `{
            SH_Tasks() {
                 ID   
                 Hours
                 EA_Task(){
                ID
                     TaskName
                 }      
            }
         }`;
       
          } else if (this.props.contract == "Rigs") {
            lastPath = "~GraphQL";
            body = `{
                      EC_ContractRigs() {  	
                          EA_Rig(){        
                        ID
                        RigName
                      }
                          }
                    }`;

          } else if (this.props.contract == "Consumables") {
            lastPath = "~GraphQL";
           body  = `{
                      EC_ContractConsumables() {  	      
                          EA_Consumable(){        
                              ID
                            ConsumableName
                         }
                      }
                    }`;         

          } else if (this.props.contract == "ConConsumables") {
          lastPath = "~GraphQL";
          body = `{
            SH_Consumables() {
                ID   
                Quantity
                EA_Consumable(){
                    ID
                    ConsumableName
                }      
            }
    }`;

          } else if (this.props.contract == "Sites") {
            lastPath = "~GraphQL";
            body = `{
                      EC_ContractSite(){
                      ID
                      SiteName
                      EA_Location(){
                          ID
                          LocationName
                      }
                      }
                    }`;
          } else if (this.props.contract == "Operators") {
            lastPath = "~GraphQL";
            body = `{
                        EA_PersonnelP() {
                            ID
                            Link_Value(){
                                ID
                                FirstName
                                LastName
                            }
                            EA_PersonnelType(){
                                ID
                                PersonnelType    
                            }
                        } 
                    }`;
          } else {
            lastPath = "~GetAll/";
          }
        } 
        else if (this.props.SignOffComponent) {
          if (this.props.SignOffComponent == "MainTable") {
            lastPath = "~GraphQL";
            // console.log("::>> ", url);
            body = `{
                      SH_Shifts() {
                            ID 
                            DayNight
                            Date
                            StatusCompanyName
                            EA_Rigs(){
                                ID    
                                RigName        
                            }        
                      }
                    }`;

          } else if (this.props.SignOffComponent == "BottomTable") {
            lastPath = "~GraphQL";
            body = `{
                      SH_Tasks() {
                        ID   
                        Hours
                        EA_Task(){
                          TaskName
                        }      
                      }
                    }`;

          } else if (this.props.SignOffComponent == "BottomTable_Consumables") {
            lastPath = "~GraphQL";
            body = `{
                      SH_Consumables() 
                        ID   
                        Quantity
                        EA_Consumable(){
                          ConsumableName
                        }      
                      }
                    }`;

          } else {
            lastPath = "~GetAll/";
          }
        } else {
          lastPath = "~GetAll/";
        }
        fetch(url + lastPath, { method: "post", body: body })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (this.props.SignOffComponent) {
              var data = [];
              var len = 0;
              if (
                this.props.SignOffComponent == "BottomTable_Consumables"
              ) {
             if (
                  result.data &&
                  result.data.SH_Consumables &&
                  result.data.SH_Consumables.length > 0
                ) {
                  for (let i = 0; i < result.data.SH_Consumables.length; i++) {
                    const element = result.data.SH_Consumables[i];
                    data.push({
                      ID: element.ID,
                      DayNight: element.DayNight,
                      Date: element.Date,
                      Status: element.Status,
                      initialRigID:
                        element.EA_Rigs.length > 0
                          ? element.EA_Rigs[0].RigName.trim()
                          : "",
                    });
                  }
                  len = result.data.SH_Shifts.length;
                } 
               else if (
                  result.data &&
                  result.data.SH_Consumables &&
                  result.data.SH_Consumables.length > 0
                ) {
                  console.log(data)
                  const mappedResult = result.data.SH_Consumables.map(
                    (l) => ({
                      ID: l.ID,
                      ConsumableName: l.EA_Consumable[0].ConsumableName,
                      Quantity: l.Quantity,
                    }));
                  data = [...mappedResult];
                  len = mappedResult.length;
                }
                return resolve({
                  data:
                  query.orderBy ? 
                  _.orderBy(
                     data,
                     [data => data[query.orderBy.field].toString().toLowerCase()],
                     [query.orderDirection == 'asc' ? "asc" : "desc"]
                   ) : data ,
                  page: query.page,
                  totalCount: len,
                });
              } 
              
              
              
              else if (
                result.data &&
                result.data.SH_Shifts &&
                result.data.SH_Shifts.length > 0
              ) {
                for (let i = 0; i < result.data.SH_Shifts.length; i++) {
                  const element = result.data.SH_Shifts[i];
                  data.push({
                    ID: element.ID,
                    DayNight: element.DayNight,
                    Date: element.Date,
                    Status: element.Status,
                    initialRigID:
                      element.EA_Rigs.length > 0
                        ? element.EA_Rigs[0].RigName.trim()
                        : "",
                  });
                }
                len = result.data.SH_Shifts.length;
              } 
              else if (
                result.data &&
                result.data.SH_Tasks &&
                result.data.SH_Tasks.length > 0
              ) {
                for (let i = 0; i < result.data.SH_Tasks.length; i++) {
                  const element = result.data.SH_Tasks[i];
                  data.push({
                    ID: element.ID,
                    DayNight: element.DayNight,
                    Date: element.Date,
                    Status: element.Status,
                    initialRigID:
                      element.EA_Rigs.length > 0
                        ? element.EA_Rigs[0].RigName.trim()
                        : "",
                  });
                }
                len = result.data.SH_Shifts.length;
              } 
              else if (
                result.data &&
                result.data.SH_Tasks &&
                result.data.SH_Tasks.length > 0
              ) {
                const mappedResult = result.data.SH_Tasks.map(
                  (l) => ({
                  ID: l.ID,
                  TaskName: l.EA_Task[0].TaskName,
                  Hours: l.Hours,
                }));                    
                data = [...mappedResult];
                len = mappedResult.length;
              }
              return resolve({
                data:
                query.orderBy ? 
                _.orderBy(
                   data,
                   [data => data[query.orderBy.field].toString().toLowerCase()],
                   [query.orderDirection == 'asc' ? "asc" : "desc"]
                 ) : data ,
                page: query.page,
                totalCount: len,
              });
             
            } else if (this.props.contract) {
              console.log(this.props.contract,"--------------------line 383");
              // console.log("**************.................Checking Now")
              //-----------2 times 
              console.log(result.data,"-----------------386");
              var data = [];
              if (
                result.data &&
                result.data.EC_ContractTasks &&
                result.data.EC_ContractTasks.length > 0
              ) {
                data = result.data.EC_ContractTasks.map(
                  (x) => x.EA_Task[0]
                );
             
              } else if (
                result?.data &&
                result?.data.SH_Tasks &&
                result?.data?.SH_Tasks?.length > 0
              ) {
                console.log(result.data,"----------------------------401 line")
                data = result.data.SH_Tasks.map(
                  (x) => {
                  return   {
                      ID:x.ID,
                      Hours:x.Hours,
                      EA_Task:x.EA_Task?.[0]?.TaskName
                    }
                  }
                );
             
              } else if (
                result.data &&
                result.data.EC_ContractRigs &&
                result.data.EC_ContractRigs.length > 0
              ) {
                data = result.data.EC_ContractRigs.map(
                  (x) => x.EA_Rig[0]
                );

              } else if (
                result.data &&
                result.data.EC_ContractConsumables &&
                result.data.EC_ContractConsumables.length > 0
              ) {
                data = result.data.EC_ContractConsumables.map(
                  (x) => x.EA_Consumable[0]
                );
               
              } else if (
                result?.data &&
                result?.data.SH_Consumables &&
                result?.data?.SH_Consumables?.length > 0
              ) {
                data = result.data.SH_Consumables.map(
                  (x) => {
                  return   {
                      ID:x.ID,
                      Quantity:x.Quantity,
                      EA_Consumable:x.EA_Consumable?.[0]?.ConsumableName
                    }
                  }
                );

              } else if (
                this.props.contract &&
                this.props.contract == "DrillingType"
              ) {
                for (let index = 0; index < result.length; index++) {
                  const element = result[index];
                  console.log(
                    "::::::  Data Query Response element  >>>>>>  ",
                    element.Link_Value.ID
                  );

                  data.push({
                    ID: element.ID,
                    DrillingTypeCode: element.Link_Value.DrillingTypeCode,
                    DrillingType:
                      element.Link_Value.DrillingTypeCode +
                      " - " +
                      element.Link_Value.DrillingType,
                    LinkID: element.Link_Value.ID,
                  });
                }
              } else if (
                result.data &&
                result.data.EC_ContractSite &&
                result.data.EC_ContractSite.length > 0
              ) {
                for (
                  let index = 0;
                  index < result.data.EC_ContractSite.length;
                  index++
                ) {
                  const element = result.data.EC_ContractSite[index];
                  data.push({
                    ID: element.ID,
                    SiteName: element.SiteName,
                    LocationName: element.EA_Location[0].LocationName,
                  });

                }
              } else if (
                result.data &&
                result.data.EA_PersonnelP &&
                result.data.EA_PersonnelP.length > 0
              ) {
                for (
                  let i = 0;
                  i < result.data.EA_PersonnelP.length;
                  i++
                ) {
                  const element = result.data.EA_PersonnelP[i];

                  data.push({
                    OperatorName: `${element.Link_Value.FirstName}  ${element.Link_Value.LastName}`,
                    ID: element.ID,
                    OperatorTypeName:
                      element.EA_PersonnelType?.[0].PersonnelType,
                      OperationTypeId:element.PersonnelType?.[0].ID
                    // Status: element.Status,
                    // initialPersonnelID:
                    //   element.EA_PersonnelType.length > 0
                    //     ? element.EA_PersonnelType[0].PersonnelType.trim()
                    //     : "",
                  });

                  console.log(
                    "::::::  Data Query Response element  >>>>>>  ",
                    data
                  );
                }
                len = result.data.EA_PersonnelP.length;
                console.log(len)
              }
              console.log(query, "-------------------624");
              this.setState({ data: data });
              const index = query.pageSize * query.page;
              const endIndex = query.pageSize * (query.page + 1);
              // return resolve({
              //   data: data,
              //   page: query.page,
              //   totalCount: result.length,
              // });
          //     for(let i = 0;i >= data.length;i++){
          //       for(var key in data[i]){
          //           data[i][key] = data[i][key].toString().toUpperCase()
          //       }
          //  }
          console.log(data,"515---------------------------------------------")
              resolve({
                data:
                query.orderBy ? 
               _.orderBy(
                  data,
                  [data => data[query.orderBy.field]?.toString()?.toLowerCase()],
                  [query.orderDirection == 'asc' ? "asc" : "desc"]
                ) : data,
                page: query.page -1,
                totalCount: data.length,
              });
            } else {
              this.setState({ data: result });
              const index = query.pageSize * query.page;
              const endIndex = query.pageSize * (query.page + 1);
              var data = [];
              if (Array.isArray(result)) {
                data = result.slice(index, endIndex);
              //  data.forEach((item) => {

              //   for(var key in item){
              //    item[key] = item[key].toString().toUpperCase()
              //   //  data =>data.get[query].charAt(0).toUpperCase() + data.get[query.orderBy].slice(1),
              // }
              //  })
              }
              console.log(data)
              return resolve({
                data:
                query.orderBy ? 
               _.orderBy(
                  data,
                  [data => data[query.orderBy.field].toString().toLowerCase()],
                  [query.orderDirection == 'asc' ? "asc" : "desc"]
                ) : data,
                page: query.page ,
                totalCount: result.length,
              });
            }
          });
      })
  }

 

  render() {
    const { classes, theme } = this.props;

    var url = `${globals.host}ID_${global.Idx}/${this.props.entity}/`;
    if (this.props.parentEntity) {
      url = `${globals.host}ID_${global.Idx}/${this.props.parentEntity.name}/ID_${this.props.parentEntity.id}/${this.props.entity}/`;
    }
    if (this.props.parentEntity && this.props.grandParentEntity) {
      url = `${globals.host}ID_${global.Idx}/${this.props.grandParentEntity.name}/ID_${this.props.grandParentEntity.id}/${this.props.parentEntity.name}/ID_${this.props.parentEntity.id}/${this.props.entity}/`;
    }
    if (
      this.props.parentEntity &&
      this.props.grandParentEntity &&
      this.props.greatGrandParentEntity 
    ) {
      url = `${globals.host}ID_${global.Idx}/${this.props.greatGrandParentEntity.name}/ID_${this.props.greatGrandParentEntity.id}/${this.props.grandParentEntity.name}/ID_${this.props.grandParentEntity.id}/${this.props.parentEntity.name}/ID_${this.props.parentEntity.id}/${this.props.entity}/`;
    }

    if (
      this.props.eaChildren &&
      this.props.parentEntity &&
      this.props.grandParentEntity &&
      this.props.greatGrandParentEntity 
    ) {
      url = `${globals.host}ID_${global.Idx}/${this.props.greatGrandParentEntity.name}/ID_${this.props.greatGrandParentEntity.id}/${this.props.grandParentEntity.name}/ID_${this.props.grandParentEntity.id}/${this.props.eaChildren.name}/ID_${this.props.eaChildren.id}/${this.props.parentEntity.name}/ID_${this.props.parentEntity.id}/${this.props.entity}/`;
    }

    return (
      <div>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>Profile</MenuItem>
          <MenuItem onClick={this.handleClose}>My account</MenuItem>
          <MenuItem onClick={this.handleClose}>Logout</MenuItem>
        </Menu>
        {/* debugger; */}
        <MaterialTable
          title={this.props.title}
          columns={this.props.columns}
          icons={tableIcons}
          isLoading={this.state.isLoading}
          // defaultFilterMethod={this.filterMethod}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  var lastPathComponent = "~AddItem/";
                  if (
                    this.props.contract &&
                    this.props.contract != "DrillingType"
                    // console.log("**************.................Checking Now")
                  ) {
                  } else if (this.props.SignOffComponent) {
                  } else if (this.props.grandParentEntity) {
                    lastPathComponent = "~AddItem/";
                  }

                  let data = newData;
                  if (this.props.contract) {
                   if (this.props.contract == "Tasks") {
                      data = {
                        EA_Task: [
                          { Link_Value: { ID: Number(data.TaskName) } },
                        ],
                      }; 
                    } 
                    else  if (this.props.contract == "ConTask") {
                      data = {
                        EA_Task: [
                          { Link_Value: { ID: Number(data.EA_Task) } },
                        ],
                        Hours: Number(data.Hours),            
                      };
                    } else if (this.props.contract == "Rigs") {
                      data = {
                        EA_Rig: [{ Link_Value: { ID: Number(data.RigName) } }],
                      };
                    } 
                    else if (this.props.contract == "Consumables") {
                      data = {
                        EA_Consumable: [
                          { Link_Value: { ID: Number(data.ConsumableName) } },
                        ],
                      };
                    } 
                    else if (this.props.contract == "ConConsumables") {
                      data = {
                        EA_Consumable: [
                          { Link_Value: { ID: Number(data.EA_Consumable) } },
                        ],
                        Quantity: Number(data.Quantity),
                      }; 
                    } 
                    else if (this.props.contract == "DrillingType") {
                      data = {
                        Link_Value: { ID: Number(data.DrillingType) },
                      };
                    } else if (this.props.contract == "Sites") {
                      data = {
                        SiteName: data.SiteName,
                        EA_Location: [
                          { Link_Value: { ID: Number(data.LocationName) } },
                        ],
                      };
                    } 
                    else if (this.props.contract == "Operators") {
                      data = {
                        Link_Value: {
                          ID : data.OperatorName,
                        },
                        EA_PersonnelType: [
                          {
                           Link_Value: {
                              ID: data.OperatorTypeName,
                             
                            },
                          },
                        ],
                      };
                    }
                  } else if (this.props.SignOffComponent) {
                    if (this.props.SignOffComponent == "BottomTable") {
                      // console.log("Sign Off Data 208:::::>>>> ", data);
                      data = {
                        EA_Task: [
                          { Link_Value: { ID: Number(data.Link_Value.ID) } },
                        ],
                        Hours: Number(data.Hours),
                      };      
                    } else if (
                      this.props.SignOffComponent == "BottomTable_Consumables"
                    ) {
                      // console.log("Sign Off Data 208:::::>>>> ", data);
                      data = {
                        EA_Consumable: [
                          { Link_Value: { ID: Number(data.Link_Value.ID) } },
                        ],
                        Quantity: Number(data.Quantity),
                      };                      
                    }
                  }
                  
                  fetch(url + lastPathComponent, {
                    method: "post",
                    body: JSON.stringify(data),
                  })
                    .then((response) => {
                      this.tableRef.current &&
                        this.tableRef.current.onQueryChange();
                      return response.json();
                    })
                    .then((result) => {
                      if (this.props.refreshBitData) {
                        this.props.refreshBitData();
                      }
                      return result;
                    });
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  console.log(oldData)
                  resolve();
                  if (
                    this.props.contract &&
                    this.props.contract != "Operators"
                  ) {
                  fetch(url + "~UpdateItem/", {
                    method: "post",
                    body: JSON.stringify(newData),
                  })
                  .then((response) => {
                    return response.json();
                  })
                  .then((result) => {
                    if(this.props.contract &&
                      this.props.contract == "ConTask") {
                      fetch(`${url}ID_${oldData.ID}/EA_Task/0/~SetLinkTo` , {
                        method: "post",
                        body: JSON.stringify({
                          Link_Value:
                          {
                            ID:newData.EA_Task
                          }
                        })
                      })
                    }
                    
                    if(this.props.contract &&
                      this.props.contract == "ConConsumables") {
                      fetch(`${url}ID_${oldData.ID}/EA_Consumable/0/~SetLinkTo` , {
                        method: "post",
                        body: JSON.stringify({
                          Link_Value:
                          {
                            ID:newData.EA_Consumable
                          }
                        })
                      })
                    }

                    if (result.Result === "Updated") {
                      this.tableRef.current &&
                        this.tableRef.current.onQueryChange();
                      return result;
                    }
                  });
                }

                else if(this.props.contract &&
                  this.props.contract == "Operators") {
                  fetch(`${url}ID_${oldData.ID}/EA_PersonnelType/0/~SetLinkTo` , {
                    method: "post",
                    body: JSON.stringify({
                      Link_Value:
                      {
                        ID: newData.OperatorTypeName
                      }
                    })
                  })
                }
             
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  fetch(url + "~DeleteItem/", {
                    method: "post",
                    body: JSON.stringify(oldData),
                  })
                    .then(function (response) {
                      console.log("onRowDelete1------->>>");
                      return response.json();
                    })
                    .then((result) => {
                      console.log("onRowDelete2------->>>");
                      if (result.Result === "Deleted") {
                        this.tableRef.current &&
                          this.tableRef.current.onQueryChange();
                        return result;
                      }
                    });
                }, 600);
              }),
          }}
          tableRef={this.tableRef}
          data={async (query) => await this.onGetData(query,url)
          }
          onRowClick={(evt, selectedRow) => {
            this.setState({ selectedRow: selectedRow.tableData.id });
            if (this.props.onRowClick)
              this.props.onRowClick(selectedRow.ID, selectedRow);
          }}
          options={{
            rowStyle: (rowData) => ({
              backgroundColor:
                this.state.selectedRow === rowData.tableData.id
                  ? "#EEE"
                  : "#FFF",
            }),
            pageSize: this.state.pageSize,
            pageSizeOptions: [5, 10, 20],
            toolbar: true,
            paging: true,
            search: false,
            sorting: true,
            actionsColumnIndex: -1,
            actionsCellStyle: {
              // backgroundColor: "#039be5",
              // alignItems: "flex-end",
              // alignContent: "end",
              // color: "#039093",
              // alignSelf: "flex-end",
            },
            thirdSortClick: false
          }}
          actions={[
            {
              icon: RefreshIcon,
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () =>
                this.tableRef.current && this.tableRef.current.onQueryChange(),
            },
            
          ]}
          onChangeRowsPerPage={(pageSize) => {
            this.setState({ pageSize: pageSize });

          }}
          onOrderChange={(colId, ord) => {
            this.setState({ orderDirection: ord == 'asc' ? 'asc' : 'desc' });
            console.log(
              "OnOrderChange: [ColId: " + colId + ", Dir: " + ord + "]"
            );
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EplodEntityTable);
