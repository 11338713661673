import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import MenuItem from "@material-ui/core/MenuItem";
import EplodEntityTable from "./ListsComponents/EplodEntityTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import globals from "../globals";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import AccordionActions from "@material-ui/core/AccordionActions";
import Backdrop from "@material-ui/core/Backdrop";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import SaveIcon from "@material-ui/icons/Save";
import { FormControl, InputLabel } from "@material-ui/core";
import EplodEntityTableBits from "./ListsComponents/EplodEntityTableBits";

const Countries = ["Germany", "USA"];

const styles = (theme) => ({
  rows: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  buttonGroup: {
    marginRight: theme.spacing(1),
  },
  progress: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
});

class BitComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      miningCompanies: [],
      contracts: [],
      selectedManufacturer: null,
      selectedManufacturerName: null,
      showBackdrop: false,
      selectedTab: 0,
      manufacturerList: [],
      bitSizeList: [],
      bitTypeList: [],
      def_Manufacturer: "Sandy",
    };
    this.refreshBitData = this.refreshBitData.bind(this);
  }

  componentDidMount() {
    this.refreshBitData();
  }

  refreshBitData() {
    this.getBitSizeList();
    this.getBitTypeList();
    this.getManufacturerList();
  }

  getManufacturerList() {
    var url = `${globals.host}ID_${global.Idx}/EA_Manufacturers`;

    fetch(url, {
      method: "POST",
    }).then(async (response) => {
      var jsonData = await response.json();

      if (response.status == 200) {
        this.setState({
          manufacturerList: [
            { ID: -1, ManufacturerName: "--Select--" },
            ...jsonData,
          ],
        });
      }
    });
  }

  getBitSizeList() {
    var url = `${globals.host}ID_${global.Idx}/EA_BitSizes`;

    fetch(url, {
      method: "post",
    }).then(async (response) => {
      var jsonData = await response.json();
      //  console.log("getBitSizeList-------->>>>",jsonData);

      if (jsonData.length) {
        this.setState({
          bitSizeList: [{ ID: -1, Size_: "--Select--" }, ...jsonData],
        });
      }
    });
  }

  getBitTypeList() {
    var url = `${globals.host}ID_${global.Idx}/EA_BitTypes`;

    fetch(url, {
      method: "post",
    }).then(async (response) => {
      var jsonData = await response.json();
      console.log("getBitTypeList", jsonData);

      if (jsonData.length) {
        this.setState({
          bitTypeList: [{ ID: -1, BitType: "--Select--" }, ...jsonData],
        });
      }
    });
  }

  render() {
    const { classes, theme } = this.props;
    const { selectedTab } = this.state;

    return (
      <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.rows}>
          {/* <Link color="inherit" href="/" onClick={this.handleClick}>
            Material-UI
          </Link>
          <Link
            color="inherit"
            href="/getting-started/installation/"
            onClick={this.handleClick}
          >
            Core
          </Link> */}
          <Typography color="textPrimary">Eplod III</Typography>
        </Breadcrumbs>
        <Paper className={classes.rows}>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) =>
              this.setState({ selectedTab: newValue })
            }
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Bit Sizes" />
            <Tab label="Bit Types" />
            <Tab label="Manufacturers" />
          </Tabs>
        </Paper>
        {/* <Button
          variant="contained"
          color="primary"
          classes={classes.button}
          endIcon={<SaveIcon />}
        >
          Export to excel
        </Button> */}
        {selectedTab === 2 && (
          <Paper style={{ padding: "15px" }}>
            Choose a manufacturer to manage bits
          </Paper>
        )}

        <div className={classes.rows}>
          {selectedTab === 0 && (
            <EplodEntityTable
              entity={"EA_BitSizes"}
              columns={[
                {
                  title: "Size",
                  field: "Size_",
                  fullWidth: true,
                  width: "100%",
                  defaultSort: "asc",
                },
                { title: "ID", field: "ID", hidden: true },
              ]}
              title={"Bit Sizes"}
              refreshBitData={this.refreshBitData}
            />
          )}
          {selectedTab === 1 && (
            <EplodEntityTable
              entity={"EA_BitTypes"}
              columns={[
                { title: "ID", field: "ID", hidden: true },
                {
                  title: "Bit Type",
                  field: "BitType",
                  fullWidth: true,
                  width: "100%",
                  defaultSort: "asc",
                },
              ]}
              title={"Bit Types"}
              refreshBitData={this.refreshBitData}
            />
          )}
          {selectedTab === 2 && (
            <EplodEntityTable
              entity="EA_Manufacturers"
              columns={[
                { title: "ID", field: "ID", hidden: true },
                {
                  title: "Name",
                  field: "ManufacturerName",
                  fullWidth: true,
                  width: "100%",
                  defaultSort: "asc",
                },
              ]}
              title="Manufacturers"
              refreshBitData={this.refreshBitData}
              onRowClick={(value, rowdata) => {
                // console.log("Clicked row ID::::::>>>>> ", rowdata);
                this.setState({
                  selectedManufacturer: value,
                  selectedManufacturerName: rowdata.ManufacturerName,
                });
              }}
            />
          )}
        </div>

        <div style={{ padding: theme.spacing(1) }} className={classes.rows}>
          {selectedTab === 2 && this.state.selectedManufacturer !== null && (
            // <EplodEntityTable
            //   parentEntity={
            //     name: "EA_Manufacturers",
            //     id: this.state.selectedManufacturer,
            //   }}
            //   entity="EA_Bits"
            //   columns={[
            //     { title: "ID", field: "ID", hidden: true },
            //     { title: "Name", field: "BitName" },
            //     {
            //       title: "Bit Type",
            //       field: "ID_EA_BitType",
            //       // lookup: Object.fromEntries(
            //       //   this.props.bitTypes.map((t) => [t.ID, t.BitType])
            //       // ),
            //     },
            //     {
            //       title: "Bit Size",
            //       field: "ID_EA_BitSize",
            //       // lookup: Object.fromEntries(
            //       //   this.props.bitSizes.map((t) => [t.ID, t.Size_])
            //       // ),
            //     },
            //   ]}
            //   title="Bits"
            // />
            <EplodEntityTableBits
              // entity={"EA_Bits"}
              bit={"Manufacturers"}
              entity={{
                name: "EA_Manufacturers",
                id: this.state.selectedManufacturer,
              }}
              columns={[
                {
                  title: "Name",
                  field: "BitName",
                  width: "25%",
                  defaultSort: "asc",
                },
                // {
                //   title: "Manufacturer",
                //   field: "ManufacturerName",
                //   fullWidth: true, width: "100%",

                //   editComponent: ({ value, onChange, rowData }) => (
                // <FormControl
                //   variant="outlined"
                //   className={classes.formControl}
                //   hiddenLabel={true}
                //   fullWidth={true}
                // >
                //       <Select
                //         native
                //         value={value}
                //         onChange={(event) => {
                //           onChange(event.target.value);
                //         }}
                //       >
                //         {this.state.manufacturerList.map((manufacturer) => (
                //           <option
                //             key={manufacturer.ID}
                //             value={manufacturer.ID}
                //           >
                //             {manufacturer.ManufacturerName}
                //           </option>
                //         ))}
                //       </Select>
                //     </FormControl>
                //   ),
                // },
                {
                  title: "Bit Size",
                  field: "E_EA_BitSizes",
                  width: "25%",
                  defaultSort: "asc",
                  editComponent: ({ value, onChange, rowData }) => (
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      hiddenLabel={true}
                      fullWidth={true}
                    >
                      <Select
                        native
                        value={value}
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                      >
                        {this.state.bitSizeList.map((bitSize) => (
                          <option
                            key={bitSize.ID}
                            value={bitSize.ID}
                            // value={bitSize.Size_}
                          >
                            {bitSize.Size_}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  ),
                },
                {
                  title: "Bit Type",
                  field: "E_EA_BitTypes",
                  width: "25%",
                  defaultSort: "asc",
                  editComponent: ({ value, onChange, rowData }) => (
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      hiddenLabel={true}
                      fullWidth={true}
                    >
                      <Select
                        native
                        value={value}
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                      >
                        {this.state.bitTypeList.map((bitType) => (
                          <option
                            key={bitType.ID}
                            value={bitType.ID}
                            // value={bitType.BitType}
                          >
                            {bitType.BitType}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  ),
                },
                { title: "ID", field: "ID", hidden: true },
              ]}
              title={`Bits  - ${this.state.selectedManufacturerName}`}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BitComponent);
