import React from "react";
import { forwardRef } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import RefreshIcon from "@material-ui/icons/Refresh";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import globals from "../../globals";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: green[500],
    },
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  RefreshIcon: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const styles = (theme) => ({
  rows: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
});

class RigsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "Name", field: "RigName" },
        { title: "ID", field: "ID", hidden: true },
      ],
    };
    this.tableRef = React.createRef();
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <MaterialTable
        title="Rigs"
        columns={this.state.columns}
        icons={tableIcons}
        editable={{
          
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                var url =
                  `${globals.host}/ID_22/EA_Rigs/~AddItem/`;
                fetch(url, { method: "post", body: JSON.stringify(newData) })
                  .then((response) => {
                    // console.log(response.json());
                    return response.json();
                  })
                  .then((result) => {
                    // console.log(result);
                    return result;
                  });
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                var url =
                  "http://localhost:44393/api/Eplods/ID_x/EA_Rigs/~UpdateItem/";
                fetch(url, { method: "post", body: JSON.stringify(oldData) })
                  .then((response) => {
                    // console.log(response.json());
                    return response.json();
                  })
                  .then((result) => {
                    // console.log(result);
                    if (result.Result === "Updated") {
                      if (oldData) {
                        this.setState((prevState) => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }
                    return result;
                  });
              }, 600);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                var url =
                  "http://localhost:44393/api/Eplods/ID_x/EA_Rigs/~DeleteItem/";
                fetch(url, { method: "post", body: JSON.stringify(oldData) })
                  .then((response) => {
                    // console.log(response.json());
                    return response.json();
                  })
                  .then((result) => {
                    // console.log(result);
                    return result;
                  });
                this.setState((prevState) => {
                  const data = [...prevState.data];
                  data.splice(data.indexOf(oldData), 1);
                  return { ...prevState, data };
                });
              }, 600);
            }),
        }}
        data={(query) =>
          new Promise((resolve, reject) => {
            // let url = 'https://api.eplod.redochre.cloud/api/Eplods/ID_x/EA_Rigs/';
            // var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
            // url += 'per_page=' + query.pageSize
            // url += '&page=' + (query.page + 1)
            var url = "http://localhost:44393/api/Eplods/ID_x/EA_Rigs/";
            fetch(url, { method: "post" })
              .then((response) => {
                return response.json();
              })
              .then((result) => {
                this.setState({ data: result });
                const index = query.pageSize * query.page;
                const endIndex = query.pageSize * (query.page + 1);
                return resolve({
                  data: result.slice(index, endIndex),
                  page: query.page,
                  totalCount: result.length,
                });
              });
          })
        }
        actions={[
          {
            icon: RefreshIcon,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () =>
              this.tableRef.current && this.tableRef.current.onQueryChange(),
          },
        ]}
        options={{
          search: true,
        }}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(RigsTable);
