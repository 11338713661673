import C from './constants';
import {store} from './store';

export const switchListTab = (selectedTab) => (
    {
        type: C.SWITCH_LIST_TAB,
        selectedTab
    }
)

export const switchTab = (selectedTab) => (
    {
        type: C.SWITCH_TAB,
        selectedTab
    }
)

export const switchContractTab = (selectedTab) => (
    {
        type: C.SWITCH_CONTRACT_TAB,
        selectedTab
    }
)
